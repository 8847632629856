import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../../actions/accountActions';
import {withRouter} from 'react-router-dom';
import Resizer from 'react-image-file-resizer';

import {ProgressBar} from 'react-materialize';
import {PopupBoxShortTop, PopupTitle, PopupDescription, PopupErrorContainer} from '../../css/styled_components/PopupComponents.js';
import {ProfileImage, ProfileImageAdd} from '../../css/styled_components/ProfileComponents';
import ErrorText from '../ErrorText';
import PrimaryButton from '../PrimaryButton';
import TextButton from '../TextButton';
import {uploadProfilePhoto} from '../../data/Storage';

const Label = styled.label`
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled(ProfileImage)`
  cursor: pointer;
`;

const ImageInput = styled.input`
  display: none;
`;

class UploadProfilePhotoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: null,
      newFile: null,
      description: 'No file selected.',
      sendingToServer: false,
      validationError: '',
      serverError: '',
      uploadProgress: null,
    };
  }
  changePhotoHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.resizePhoto(file, (blob) => {
        this.setState({
          photoUrl: URL.createObjectURL(blob),
          newFile: blob,
          description: file.name,
          validationError: '',
          serverError: '',
          uploadProgress: null,
        });
      });
    }
  };
  resizePhoto = (file, callback) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (blob) => {
        callback(blob);
      },
      'blob'
    )
  };
  validateForm = () => {
    if (this.state.newFile == null) {
      this.setState({ validationError: 'An image is required'});
      return false;
    }
    return true;
  };
  uploadPhoto = () => {
    if (this.validateForm() == true) {
      this.setState({
        validationError: '',
        serverError: '',
        sendingToServer: true,
        uploadProgress: 0
      });
      uploadProfilePhoto(this.state.newFile, (progress) => {
        this.setState({
          uploadProgress: Math.floor(progress)
        });
      }, (err) => {
        this.setState({
          uploadProgress: null,
          sendingToServer: false,
          serverError: err.message
        });
      }, (url) => {
        this.props.actions.setProfilePhotoUrl(url);
        this.props.onClose();
      });
    }
  };
  render() {
    let {validationError, serverError, photoUrl, description, sendingToServer, uploadProgress} = this.state;
    let {isEditing, onClose} = this.props;
    return (
      <PopupBoxShortTop>
        <PopupTitle>{isEditing ? 'Change photo' : 'Add photo'}</PopupTitle>
        <Label htmlFor='profilePicInput'>
          {photoUrl
            ? <Image src={photoUrl} />
            : <ProfileImageAdd>+</ProfileImageAdd>}
          <PopupDescription>{description}</PopupDescription>
        </Label>
        <ImageInput id='profilePicInput' type="file" onChange={this.changePhotoHandler} accept="image/*"/>
        {(validationError || serverError) &&
        <PopupErrorContainer>
          <ErrorText errorText={validationError} />
          <ErrorText errorText={serverError} />
        </PopupErrorContainer>
        }
        {sendingToServer
          ? <React.Fragment>
            <ProgressBar progress={uploadProgress}/>
            <PopupDescription>Please wait...</PopupDescription>
          </React.Fragment>
          : <React.Fragment>
            <PrimaryButton onClick={this.uploadPhoto}>Upload</PrimaryButton>
            <TextButton onClick={onClose}>Cancel</TextButton>
          </React.Fragment>
        }
      </PopupBoxShortTop>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(accountActions, dispatch) };
}

UploadProfilePhotoModal.propTypes = {
  isEditing: PropTypes.bool,
  onClose: PropTypes.func,
  actions: PropTypes.object,
}

export default connect(null, mapDispatchToProps)(withRouter(UploadProfilePhotoModal));
