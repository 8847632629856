import React from 'react';
import {injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';
import PropTypes from "prop-types";
class PaymentRequestForm extends React.Component {
  constructor(props) {
    super(props);

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: 599,
      },
    });

    // paymentRequest.on('token', ({complete, token, ...data}) => {
    //   complete('success');
    // });

    paymentRequest.canMakePayment().then((result) => {
      // console.log('result', result)
      this.setState({canMakePayment: !!result});
    });

    this.state = {
      canMakePayment: false,
      paymentRequest,
    };
  }

  render() {
    return this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
        style={{
          // For more details on how to style the Payment Request Button, see:
          // https://stripe.com/docs/elements/payment-request-button#styling-the-element
          paymentRequestButton: {
            theme: 'light',
            height: '64px',
          },
        }}
      />
    ) : null;
  }
}
PaymentRequestForm.propTypes = {
  stripe: PropTypes.object,
};
export default injectStripe(PaymentRequestForm);
