import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PropagateLoader } from 'react-spinners';
const Container = styled.div`
  width: 100%;
  height: 43px;
  padding-bottom: 15px;
  text-align: center;
  background-color: ${props => props.theme.primaryColor};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
class ButtonLoader extends React.Component {
  render(){
    return (
      <Container style={this.props.style}>
        <PropagateLoader
          sizeUnit={"px"}
          size={15}
          loading={this.props.loading}
          color="#FFFFFF"
        />
      </Container>
    );
  }
}
ButtonLoader.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool
};
export default ButtonLoader;
