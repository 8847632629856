import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import fire from '../config/fire';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import AccountCard from "../components/AccountCard.js";
import {formatAccountTotal} from '../utils/accountsFormatting';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';

import {DownArrowIcon} from '../components/shared_components/Icons';

const TopTitlesContainer = styled.div`
    display: flex;
    margin-top: -180px;

  .account-card-top-spacer-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10%;

    @media (min-width: 839px) {
      padding: 0;
      width: 1025px;
    }
  }
`;

const AllAccountsTitle = styled.h1`
  color: #FFFFFF;	
  font-family: "NotoSans-Regular", serif;
  font-size: 2.5rem;	
  font-weight: 800;	
  line-height: 49px;

  .a-all-accounts-svg-arrow {
    height: 9px;
    width: 30px;
    margin: 0 0 4px 4px;
}
`;

const TopSummaryInfo = styled.div`
    text-align: right;

    p {
        color: rgba(255,255,255,0.8);	
        font-family: "NotoSans-Regular", serif;	
        font-size: 1rem;	
        margin: 8px 0 8px 0;
        line-height: 19px;
    }

    h3 {
        color: #fff;	
        font-family: "NotoSans", serif;	
        font-size: 1.8rem;	
        font-weight: bold;
        margin-top: 0;
        letter-spacing: 0.5px;	
        line-height: 19px;
    }
`;

const AccountCardsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;

    .a-accounts-card-inner-grid {
      display: flex;
      flex-wrap: wrap;
      width: 1050px; 
    }
`;
class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      accountsById: {},
      updatingInstitutionsNumber: 0,
      updating: 0
    };
    this.institutions = fire.firestore().collection('institutions')
    this.payments = fire.firestore().collection('payments')
  }
  componentDidMount() {
    this.getDataFromDatabase(this.props.profile.currentClientId)
  }
  getDataFromDatabase = (clientId) => {
    let _this = this;
    let query = this.institutions.where("user_id", "==", clientId );
    query.get().then(function(querySnapshot) {
      let accounts = []
      let accountsById = {}
      querySnapshot.forEach(function(doc) {
        accountsById[doc.id] = doc.data();
        let accountsArray =  accountsById[doc.id].accounts;
        let accountsObject = {};
        accountsArray.forEach((account) => {
          accountsObject[account.account_id] = account
        });
        accountsById[doc.id].accounts = accountsObject;
        accounts.push(doc.data());
        // doc.data() is never undefined for query doc snapshots
      });
      _this.setState({
        accounts: accounts,
        accountsById: accountsById,
        updating: 0
      })
    });
  };
  calculateAllAccountsSum = (accounts) => {
    let sum = 0;
    accounts.forEach((account) => {
      if(account.type !== 'loan' && account.type !== 'credit') {
        sum += account.balances.current
      }
      if(account.type === 'credit') {
        sum -= account.balances.current
      }
    });

    return sum;
  }
  renderTotalSum = (accounts) => {
    if (accounts.length === 0) {
      return null;
    }

    let totalSum = 0;
    accounts.forEach(item => {
      item.accounts.forEach((value) => {
        if(value.type !== 'loan' && value.type !== 'credit') {
          totalSum += value.balances.current
        }
        if(value.type === 'credit') {
          totalSum -= value.balances.current
        }
      });
    })

    return totalSum;
  };

  onClickSetState = (account, accountsNumber) => {
    if(accountsNumber === 1) {
      this.props.actions.setTransactions(account.accounts[0])
      this.props.history.push('/transactions')
      return
    }
    this.props.actions.setAccount(account);

    this.props.history.push('/account-expanded');
  };
  render(){
    const {accounts} = this.state

    return (
      <React.Fragment>
        <TopTitlesContainer>
        <FlexSpacer/>
          <div className="account-card-top-spacer-container">
            <AllAccountsTitle>
              All Accounts {this.state.updating? '(updating...)': ''}
              <svg className="a-all-accounts-svg-arrow" viewBox="0 50 100 60">{DownArrowIcon}</svg>
            </AllAccountsTitle>
            <FlexSpacer/>
            <TopSummaryInfo>
              <p>Total Balance</p>
              <h3>{formatAccountTotal(this.renderTotalSum(this.state.accounts))}</h3>
            </TopSummaryInfo>
          </div>
          <FlexSpacer/>
        </TopTitlesContainer>

        <AccountCardsGrid>
        <FlexSpacer/>
            <div className="a-accounts-card-inner-grid">
            {accounts.map((item,i) =>
              <AccountCard key={i}
                onClickSetState={() => this.onClickSetState(item, item.accounts.length)}
                isMultiple={item.accounts.length > 1}
                title={item.name}
                accountTypes={item.accounts}
                total={this.calculateAllAccountsSum(item.accounts)}
                logo={item.logo}
                />
            )}
            </div>
          <FlexSpacer/>
        </AccountCardsGrid>

        <BottomLogoSection/>

      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions, dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
Accounts.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object,
  account: PropTypes.object,
  actions: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Accounts));

