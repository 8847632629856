import styled from 'styled-components';

export const ProfileImage = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;

export const ProfileImageAdd = styled.div`
  width: 90px;
  height: 90px;
  margin: 0;
  border: none;
  border-radius: 50%;
  text-align: center;
  font-size: 40px;
  font-weight: 200;
  line-height: 90px;
  background-color: ${props => props.theme.secondaryColor};
  color: ${props => props.theme.whiteText};
`;
