import validator from 'validator';
export default function isValid (value, type) {
  let isValid = 1;
  switch(type) {
    case 'email':
      if (!validator.isEmail(value)) {
        isValid = 0;
      }
      break;
    case 'required':
      if (!value.toString().trim().length) {
        isValid = 0;
      }
      break;
    case 'min6':
      if (value.toString().trim().length < 6) {
        isValid = 0;
      }
      break;
    default:
      break;
  }
  return isValid;
}
