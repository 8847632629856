import React from 'react';
import styled from 'styled-components'
import PropTypes from "prop-types";
import {GreenLogo} from '../components/Logos';
import {Link} from 'react-router-dom'
import Images from "../images/Images";
import fire from "../config/fire";
const IconContainer = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  margin-right: 5px;
`;
const AdvisorNavBarContainer = styled.div`
  position: absolute;
  top: 0;
  height: 64px;
  width: 100%;
  display: inline-flex;
  padding: 0 16px;
  background-color: #454b54;
  align-items: center;
  justify-content: center;
`;
const LogoContainer = styled.div`
  display: flex;
`;
const LogoText = styled.div`
  color: #66bb6a;
  font-family: 'NotoSans-Regular',serif ;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 1px 0;
  margin-left: 10px;
`;
const BackToClientsButton = styled(Link)`
  background-color: #5b6068;
  position: absolute;
  left: 50px;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoutButton = styled.div`
  background-color: #5b6068;
  position: absolute;
  right: 50px;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor:pointer;
  }
`;
class AdvisorTopBar extends React.Component {
  constructor(props){
    super(props)
    this.state={
      displayOnRoutes: [
        '/advisor/dashboard',
        '/account-expanded',
        '/transactions',
        '/assets',
        '/assets-details/:',
        '/accounts',
        '/budget',
        '/dashboard'
      ]
    }
  }
  shouldDisplay = () => {
    return this.checkShouldDisplay(this.props.path, this.state.displayOnRoutes);
  };
  checkShouldDisplay = (path, arr) => {
    for (var i = 0; i < arr.length; i++) {
      if (!arr[i].includes(':') && arr[i] == path) {
        return true;
      } else if (arr[i].includes(':') && this.pathsMatch(arr[i], path)) {
        return true;
      }
    }
    return false;
  };
  pathsMatch = (first, second) => {
    let i = 0;
    while (first[i] == second[i]) {
      i++;
    }
    return first.substring(i) == ':' && second.substring(i).includes('/') == false;
  };
  logout = () => {
    fire.auth().signOut().then(() => {
      this.props.history.push('/');
    })
  };
  render() {
    const isDisplayed = this.shouldDisplay();
    return (
        <React.Fragment>
          {isDisplayed ?
            <AdvisorNavBarContainer>
              {this.props.path === '/advisor/dashboard' ?
                <div/>
                :
                <BackToClientsButton to='/advisor/dashboard'>
                  <IconContainer image={Images.back} />
                  back to clients
                </BackToClientsButton>
              }
              <LogoContainer>
                <GreenLogo/>
                <LogoText>advisor</LogoText>
              </LogoContainer>
              <LogoutButton onClick={this.logout}>
                Log out
              </LogoutButton>
            </AdvisorNavBarContainer>
            :
            <div />
          }
        </React.Fragment>
    );
  }

}
AdvisorTopBar.propTypes = {
  path:PropTypes.string,
  history: PropTypes.object
};
export default AdvisorTopBar;
