import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {netWorthActions} from '../../actions/netWorthActions';
import PropTypes from 'prop-types';
import { PopupBox, PopupTitle, PopupSquareImage, PopupErrorContainer } from '../../css/styled_components/PopupComponents.js';
import PrimaryInput from '../PrimaryInput';
import PrimaryButton from '../PrimaryButton';
import TextButton from '../TextButton';
import ErrorText from '../ErrorText';
import FormValidator from '../FormValidator';
import {addAsset, updateAsset, deleteAsset} from '../../data/NetWorth';

class AssetRegular extends React.Component {
  constructor(props) {
    super(props)
    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Title is required.'
      },
      {
        field: 'amount',
        method: 'isEmpty',
        validWhen: false,
        message: 'Amount is required.'
      },
      {
        field: 'amount',
        method: this.amountIsNotZero,
        validWhen: true,
        message: 'Amount cannot be zero.'
      }
    ]);
    this.submitted = false;
    this.isEditing = false;
    if (this.props.editItem) {
      this.isEditing = true;
      this.state = {
        name: this.props.editItem.name,
        amount: this.props.editItem.amount,
        validation: this.validator.valid()
      }
    } else {
      this.state = {
        name:'',
        amount:'',
        validation: this.validator.valid()
      };
    }
  }
  amountIsNotZero = () => {
    return this.state.amount > 0;
  };
  handleInputChange = (e) =>  {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  validateForm = () => {
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    return validation.isValid;
  };
  addWithMore = () => {
    if (this.validateForm() == true) {
      addAsset(this.state.name, this.state.amount, this.props.category.dto, '').then((newAsset) => {
        this.submitted = false;
        this.setState({
          name: '',
          amount: ''
        });
        this.props.actions.addAsset(newAsset);
        this.props.onSuccess();
      }).catch((err) => {
        this.handleError(err);
      });
    }
  };
  addSingle = () => {
    if (this.validateForm() == true) {
      addAsset(this.state.name, this.state.amount, this.props.category.dto, '').then((newAsset) => {
        this.submitted = false;
        this.props.actions.addAsset(newAsset);
        this.props.onSuccess();
        this.props.onClose();
      }).catch((err) => {
        this.handleError(err);
      });
    }
  };
  update = () => {
    if (this.validateForm() == true) {
      updateAsset(this.props.editItem, this.state.name, this.state.amount).then((newAsset) => {
        this.submitted = false;
        this.props.actions.updateAsset(newAsset);
        this.props.onSuccess();
        this.props.onClose();
      }).catch((err) => {
        this.handleError(err);
      })
    }
  };
  delete = () => {
    deleteAsset(this.props.editItem.id).then((id) => {
      this.submitted = false;
      this.props.actions.deleteAsset(id);
      this.props.onSuccess();
      this.props.onClose();
    }).catch((err) => {
      this.handleError(err);
    })
  };
  handleError = () => {
    //TODO
  };
  render() {
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    let isEditing = this.isEditing
    return (
      <PopupBox>
        <PopupSquareImage src={this.props.category.icon}/>
        <PopupTitle style={{marginBottom: '10px'}}>{this.props.category.description}</PopupTitle>
        <PopupErrorContainer>
          <ErrorText errorText={validation.name.message} />
          <ErrorText errorText={validation.amount.message} />
        </PopupErrorContainer>
        <PrimaryInput value={this.state.name}
                      name="name"
                      onChange={(e) => this.handleInputChange(e)}
                      label="Title"
                      type="text"
                      validationtype="text"
                      fieldname="Title"
                      haserror={validation.name.isInvalid.toString()}
                      style={{marginBottom: '16px'}}/>
        <PrimaryInput value={this.state.amount}
                      name="amount"
                      onChange={(e) =>this.handleInputChange(e)}
                      label="Amount"
                      fieldname="Amount"
                      haserror={validation.amount.isInvalid.toString()}/>
        { isEditing
          ? <React.Fragment>
              <PrimaryButton onClick={this.update} style={{marginTop: '30px'}}>
                Save
              </PrimaryButton>
              <TextButton onClick={this.delete} errorStyle>
                Delete
              </TextButton>
            </React.Fragment>
          : <React.Fragment>
              <PrimaryButton onClick={this.addWithMore} style={{marginTop: '30px'}}>
                Save and add more
              </PrimaryButton>
              <PrimaryButton onClick={this.addSingle}
                             style={{marginTop: "10px"}}
                             outlinedStyle>
                Save
              </PrimaryButton>
            </React.Fragment>
         }

      </PopupBox>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(netWorthActions, dispatch) };
}
AssetRegular.propTypes = {
  actions: PropTypes.object,
  category: PropTypes.object,
  editItem: PropTypes.object,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
}
export default connect(null, mapDispatchToProps)(AssetRegular);
