export const AssetCategory = {
  CHECKING_AND_SAVINGS_ACCOUNTS: {
    id: 1,
    dto: 'checkingAndSavingsAccounts',
    path: 'savings-accounts',
    icon: require('../images/icons/checking.svg'),
    description: 'Checking and saving accounts'
  },
  CROPS_FOR_SALE: {
    id: 2,
    dto: 'cropsForSale',
    path: 'crops-for-sale',
    icon: require('../images/icons/crops.svg'),
    description: 'Crops held for sale/feed'
  },
  GROWING_CROPS: {
    id: 3,
    dto: 'growingCrops',
    path: 'growing-crops',
    icon: require('../images/icons/investmentGrowing.svg'),
    description: 'Investment in growing crops'
  },
  COMMERCIAL_FEED: {
    id: 4,
    dto: 'commercialFeed',
    path: 'commercial-feed',
    icon: require('../images/icons/commercialFeed.svg'),
    description: 'Commercial feed on hand'
  },
  ACCOUNTS_RECEIVABLE: {
    id: 5,
    dto: 'accountsReceivable',
    path: 'accounts-receivable',
    icon: require('../images/icons/accountsReceivable.svg'),
    description: 'Accounts receivable'
  },
  INPAID_COOP: {
    id: 6,
    dto: 'inpaidCoop',
    path: 'inpaid-coop',
    icon: require('../images/icons/inpaid.svg'),
    description: 'Inpaid co-op, distributions'
  },
  LIVESTOCK: {
    id: 7,
    dto: 'livestock',
    path: 'livestock',
    icon: require('../images/icons/breedingLivestock.svg'),
    description: 'Breeding livestock'
  },
  MACHINERY: {
    id: 8,
    dto: 'machinery',
    path: 'machinery',
    icon: require('../images/icons/machinery.svg'),
    description: 'Machinery & equipment'
  },
  BUILDINGS: {
    id: 9,
    dto: 'buildings',
    path: 'buildings',
    icon: require('../images/icons/buildings.svg'),
    description: 'Buildings/improvements'
  },
  FARMLAND: {
    id: 10,
    dto: 'farmland',
    path: 'farmland',
    icon: require('../images/icons/farmland.svg'),
    description: 'Farmland'
  },
  FARM_SECURITIES: {
    id: 11,
    dto: 'farmSecurities',
    path: 'farm-securities',
    icon: require('../images/icons/farmSecurities.svg'),
    description: 'Farm securities, certificates'
  },
  OTHER_FIXED_ASSETS: {
    id: 12,
    dto: 'otherFixedAssets',
    path: 'other-fixed-assets',
    icon: require('../images/icons/other.svg'),
    description: 'Other fixed assets'
  },
  REAL_ESTATE: {
    id: 13,
    dto: 'realEstate',
    path: 'real-estate',
    icon: require('../images/icons/realEstate.svg'),
    description: 'Real estate'
  },
  AUTO_BOATS: {
    id: 14,
    dto: 'autoBoats',
    path: 'auto-boats',
    icon: require('../images/icons/auto.svg'),
    description: 'Automobiles, boats, etc.'
  },
  HOUSEHOLD_GOODS: {
    id: 15,
    dto: 'householdGoods',
    path: 'household-goods',
    icon: require('../images/icons/householdGoods.svg'),
    description: 'Household goods, clothing'
  },
  STOCKS_BONDS: {
    id: 16,
    dto: 'stocksBonds',
    path: 'stocks-bonds',
    icon: require('../images/icons/stocks.svg'),
    description: 'Stocks, bonds, etc.'
  }
};
