import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import closeImg from '../images/close.svg'

const Container = styled.div`
  background-color: rgba(69, 75, 84, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChildrenContainer = styled.div`
  display: inline-block;
`;

const CloseIcon = styled.img`
  display: inline-block;
  vertical-align: top;
  width: 26px;
  height: 26px;
  margin-left: 19px;
  cursor: pointer;
`;

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDom.createPortal(
      <Container>
        <div>
          <ChildrenContainer>
            {this.props.children}
          </ChildrenContainer>
          <CloseIcon src={closeImg} onClick={this.props.onClose}/>
        </div>
      </Container>,
      this.el
    );
  }
}
Modal.propTypes = {
  children: PropTypes.node,
  root: PropTypes.node,
  onClose: PropTypes.func
}
export default Modal;
