import styled from "styled-components";
import {Link} from "react-router-dom";
export const Title =  styled.div`
	color: ${props=>props.theme.secondaryColor};
	font-family: "NotoSans-Bold", serif;
	font-size: 28px;
	line-height: 49px;
`;
export const Subtitle = styled.div`
  color: ${props=>props.theme.thirdColor};
	font-family: "NotoSans-Regular", serif;
	font-size: 14px;
	line-height: 16px;
`;
export const TitleOnGray = styled.div`
  color: ${props=>props.theme.secondaryColor};
  font-family: "NotoSans-Bold", serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
`;
export const SubtitleOnGray = styled.div`
  color: ${props=>props.theme.thirdColor};
  font-family: "NotoSans-Regular", serif;
  font-size: 16px;
  line-height: 22px;
`;
export const ActionText = styled.div`
  font-size: 11px ;
  font-weight: 600 ;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.secondaryColor};
  height: 20px ;
  line-height: 21px ;
`;
export const ActionLink = styled(Link)`
  font-size: 11px ;
  font-weight: 600 ;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.primaryColor};
  height: 20px ;
  line-height: 21px ;
`;
export const ActionPrimaryText = styled.div`
  font-size: 11px ;
  font-weight: 600 ;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.primaryColor};
  height: 20px ;
  line-height: 21px ;
  &:hover{
     cursor: pointer;
  }
`;
export const BoxTitle = styled.div`
  font-size: 16px ;
  font-weight: 600 ;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.secondaryColor};
  height: 20px ;
  line-height: 21px ;
  padding-bottom: 10px;
`;
export const BoxDescription = styled.div`
    color: ${props=>props.theme.thirdColor};
  	font-family: "NotoSans-Regular", serif;	
  	font-size: 12px;	
  	line-height: 16px;
  	text-align: center;
`;
export const PriceElement = styled.div`
  font-size: 24px ;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.secondaryColor};
`;
export const NormalText = styled.div`
    color: ${props=>props.theme.secondaryColor};
  	font-family: "NotoSans-Regular", serif;	
  	font-size: 12px;	
  	line-height: 16px;
  	text-align: center;
`;
export const BigText = styled.div`
    color: ${props=>props.theme.secondaryColor};
  	font-family: "NotoSans-Regular", serif;	
  	font-size: 16px;
  	line-height: 16px;
  	text-align: center;
`;
