import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {netWorthActions} from '../actions/netWorthActions';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import {TopTitlesContainer, RowContainer, BackArrowButton, BackArrowSvg, AssetsTitle} from '../css/styled_components/AssetsComponents';
import {LeftBackArrowIcon, PencilIcon} from '../components/shared_components/Icons';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
import Modal from '../components/Modal';
import AssetRegular from '../components/modal/AssetRegular';
import {AssetCategory} from '../constants/AssetCategory';
import {formatAccountTotal, formatAccountTotalFull} from '../utils/accountsFormatting';
import {getNetWorthWithSavingAccounts} from '../data/NetWorth';

const TopBox = styled.div`
  padding: 66px 30px;
  text-align: center;
  background-color: ${props => props.theme.defaultBackground};
  box-shadow: ${props => '0 10px 40px 0 ' + props.theme.boxShadow};
  border-radius: 2px;
`;

const TopBoxTitle = styled.p`
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: 'NotoSans-Regular', serif;
  color: ${props => props.theme.thirdColor};
  text-transform: capitalize;
`;

const TopBoxAmount = styled.p`
  margin: 0;
  font-size: 26px;
  font-family: 'Noto Serif', serif;
  font-weight: bold;
  color: ${props => props.theme.secondaryColor};
`;

const TopBoxDecimals = styled.span`
  font-size: 14px;
`;

const DetailsBox = styled.div`
  margin-top: 30px;
  background-color: ${props => props.theme.defaultBackground};
  border-radius: 2px;
  box-shadow: ${props => '0 10px 40px 0 ' + props.theme.boxShadow};
`;

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
  border-bottom: ${props => '1px solid ' + props.theme.separator};
  position: relative;

  :last-child {
    border-bottom: none;
  }
`;

const EditButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  &:focus {
    background-color: transparent;
  }
`;

const PencilIconSvg = styled.svg`
  width: 24px;
  height: 24px;
  margin-right: 30px;
`;

const DetailsTexts = styled.div`
  flex: 1;
`;

const DetailsTitle = styled.span`
  display: block;
  color: ${props => props.theme.secondaryColor};
  font-family: 'NotoSans-Regular', serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
`;

// const DetailsExtra = styled.span`
//   display: block;
//   margin-top: 6px;
//   color: ${props => props.theme.thirdColor};
//   font-family: 'NotoSans-Regular', serif;
//   font-size: 16px;
//   line-height: 22px;
// `;

const DetailsAmount = styled.span`
  height: 24px;
  color: ${props => props.theme.secondaryColor};
  font-size: 18px;
  line-height: 24px;
  font-family: 'Noto Serif', serif;
  font-weight: bold;
`;

class NetWorthAssetsDetails extends React.Component {
  constructor(props) {
    super(props)
    let categoryPath = this.props.match.params.category;
    let category = Object.values(AssetCategory).find((cat) => { return cat.path === categoryPath });
    this.state = {
      showAddModal: false,
      selectedEditItem: '',
      category: category,
      assets: [],
      total: 0,
      formattedTotal: '',
      formattedDecimals: ''
    }
  }
  componentDidMount() {
    if(!this.props.data.isSuccess) {
      this.props.actions.fetchNetWorthStart();
      getNetWorthWithSavingAccounts().then((data) => {
        this.props.actions.fetchNetWorthSuccess(data);
				this.filterAndUpdate();
      }).catch((e) => {
        this.props.actions.fetchNetWorthFailure(e);
      })
    } else {
			this.filterAndUpdate();
		}
  }
  filterAndUpdate = () => {
    let assets = this.props.data.assets.filter((item) => { return item.category === this.state.category.dto });
    let amount = assets.map(item => item.amount).reduce((prev, next) => prev + next);
    let formattedFull = formatAccountTotalFull(amount);
    let formattedTotal = formatAccountTotal(amount);
    let formattedDecimals = formattedFull.substring(formattedFull.indexOf('.'));
    this.setState({
      assets: assets,
      total: amount,
      formattedTotal: formattedTotal,
      formattedDecimals: formattedDecimals
    })
  };
  handleShowModal = () => {
    this.setState({
      showAddModal: true
    });
  };
  handleCloseModal = () => {
    this.setState({
      showAddModal: false,
      selectedEditItem: ''
    })
  };
  showEditModal = (itemId) => {
    this.setState({
      showAddModal: true,
      selectedEditItem: this.state.assets.find((item) => {return item.id === itemId})
    })
  };
  render() {
    const addModal = this.state.showAddModal
      ? (<Modal onClose={this.handleCloseModal}>
          <AssetRegular
            category={this.state.category}
            editItem={this.state.selectedEditItem}
            onSuccess={this.filterAndUpdate}
            onClose={this.handleCloseModal}/>
        </Modal>) : null;
    return (
      <React.Fragment>
        <TopTitlesContainer>
          <FlexSpacer/>
          <div className="top-spacer-container">
            <BackArrowButton onClick={() => this.props.history.goBack()}>
              <BackArrowSvg viewBox="0 0 32 32">
                  {LeftBackArrowIcon}
              </BackArrowSvg>
              <AssetsTitle>
                  {this.state.category.description}
              </AssetsTitle>
            </BackArrowButton>
            <FlexSpacer/>
            <button className="plus-icon-button" onClick={() => this.handleShowModal()}>
                +
            </button>
          </div>
          <FlexSpacer/>
        </TopTitlesContainer>
        <RowContainer>
          <FlexSpacer/>
          <div className="inner-spacer-container">
            <TopBox>
              <TopBoxTitle>{this.state.category.description} Total</TopBoxTitle>
              <TopBoxAmount>{this.state.formattedTotal}<TopBoxDecimals>{this.state.formattedDecimals}</TopBoxDecimals></TopBoxAmount>
            </TopBox>
          </div>
          <FlexSpacer/>
        </RowContainer>
        <RowContainer>
          <FlexSpacer/>
          <div className="inner-spacer-container">
            <DetailsBox>
              {this.state.assets.map((item, i) =>
                <DetailsItem key={i}>
                  <EditButton onClick={() => { this.showEditModal(item.id) }}>
                    <PencilIconSvg viewBox="0 0 56 56">
                      {PencilIcon}
                    </PencilIconSvg>
                  </EditButton>
                  <DetailsTexts>
                    <DetailsTitle>{item.name}</DetailsTitle>
                    {/*<DetailsExtra>{item.extra}</DetailsExtra>*/}
                  </DetailsTexts>
                  <DetailsAmount>{formatAccountTotalFull(item.amount)}</DetailsAmount>
                </DetailsItem>
              )}
            </DetailsBox>
          </div>
          <FlexSpacer/>
        </RowContainer>
        <BottomLogoSection/>
        {addModal}
      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(netWorthActions, dispatch) };
}
function mapStateToProps({netWorth}){
  return { data: netWorth};
}
NetWorthAssetsDetails.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  actions: PropTypes.object,
  data: PropTypes.object
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NetWorthAssetsDetails));
