import {NetWorthActionTypes} from "../constants/NetWorthActionTypes";

export function netWorth(state = {}, action) {
  var newAssets;
  var oldAsset;
  var position;
  switch (action.type){
    case NetWorthActionTypes.FETCH_NET_WORTH_START:
      return {
        ...state,
        isFetching: true,
        error: ''
      };
    case NetWorthActionTypes.FETCH_NET_WORTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        error: null,
        lastFetchTimeMillis: Date.now(),
        assets: action.data.assets,
        liabilities: action.data.liabilities,
        assetsInAccountsTotal: action.data.assetsInAccountsTotal,
        totalAssets: action.data.totalAssets,
        totalLiabilities: action.data.totalLiabilities,
        totalNetWorth: action.data.totalAssets - action.data.totalLiabilities
      }
    case NetWorthActionTypes.FETCH_NET_WORTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        error: action.error,
        assets: [],
        liabilities: [],
        assetsInAccountsTotal: '',
        totalAssets: '',
        totalLiabilities: '',
        totalNetWorth: ''
      }
    case NetWorthActionTypes.ADD_ASSET:
      if (state.assets.length > 0) {
        newAssets = state.assets.slice();
      } else {
        newAssets = [];
      }
      newAssets.push(action.asset);
      return {
        ...state,
        assets: newAssets,
        totalAssets: state.totalAssets + action.asset.amount,
        totalNetWorth: state.totalNetWorth + action.asset.amount
      };
    case NetWorthActionTypes.UPDATE_ASSET:
      newAssets = state.assets.slice();
      oldAsset = newAssets.find((item) => {return item.id == action.asset.id});
      position = newAssets.indexOf(oldAsset);
      newAssets[position] = action.asset;
      return {
        ...state,
        assets: newAssets,
        totalAssets: state.totalAssets - oldAsset.amount + action.asset.amount,
        totalNetWorth: state.totalNetWorth - oldAsset.amount + action.asset.amount
      }
    case NetWorthActionTypes.DELETE_ASSET:
      newAssets = state.assets.slice();
      oldAsset = newAssets.find((item) => {return item.id == action.id});
      newAssets = newAssets.filter((item) => {return item.id != action.id});
      return {
        ...state,
        assets: newAssets,
        totalAssets: state.totalAssets - oldAsset.amount,
        totalNetWorth: state.totalNetWorth - oldAsset.amount
      }
    default:
      return state;
  }
}
