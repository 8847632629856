import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
const Container = styled.button`
  width: 100%;
  height: 43px;
  text-align: center;
  letter-spacing: 0.5px;
  font-family: "NotoSans-Regular", serif;
  font-weight: 500;
  font-size: 13px;
  border-radius: 2px;
  color: ${props => props.outlinedStyle ? props.theme.primaryColor : "white"};
  background-color: ${props => props.outlinedStyle ? "white" : props.theme.primaryColor};
  border: ${props => props.outlinedStyle ? "1px " + props.theme.primaryColor + " solid" : "none"};

  &:hover{
    cursor: pointer;
    border: 1px #454B54 solid;
  }
  &:focus{
    background-color: ${props => props.outlinedStyle ? "white" : props.theme.primaryColor};
  }
  &:active{
    border: none;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
class PrimaryButton extends React.Component {
  render(){
    return (
      <Container onClick={this.props.onClick} style={this.props.style} id={this.props.id} outlinedStyle={this.props.outlinedStyle}>
        {this.props.children}
      </Container>
    );
  }
}
PrimaryButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
  outlinedStyle: PropTypes.bool,
  id: PropTypes.string,
};

export default PrimaryButton;
