export const formatAccountTotal = (total) => {
    if (!total) {
        return;
    }
    return formatTotal(total);
};

export const formatAccountTotalWithZero = (total) => {
  if (!total && total !== 0) {
    return;
  }
  return formatTotal(total);
}

const formatTotal = (total) => {
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

  // remove decimal points for grand total
  const subtotal = formatter.format(total);
  const index = subtotal.indexOf(".");
  return subtotal.slice(0, index);
}

export const formatAccountTotalFull = (total) => {
    if (!total) {
        return;
    }
    return formatTotalFull(total);
};

export const formatAccountTotalFullWithZero = (total) => {
  if (!total && total !== 0) {
    return;
  }
  return formatTotalFull(total);
}

const formatTotalFull = (total) => {
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
  });
  return formatter.format(total);
}

export const formatDecimalAmount = (total) => {
    if (!total) {
        return;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      // remove decimal points for grand total
    const subtotal = formatter.format(total);
    const index = subtotal.indexOf(".");
    return subtotal.slice(index, subtotal.length);
};
