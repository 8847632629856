import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import PlaidLink from 'react-plaid-link'
import {TitleOnGray, SubtitleOnGray, BoxTitle, BoxDescription} from "../components/TextElements";
import fire from "../config/fire";
import axios from "axios/index";
import Images from '../images/Images'
import {GrayLogo} from "../components/Logos";
import LoadingScreen from '../components/LoadingScreen';
import {MainWidthContainer} from '../css/styled_components/SharedComponents';
import moment from 'moment';

const AccountTypeOption = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0 20px 80px 0 rgba(0,0,0,0.2);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
`;
const OptionImage = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  width: 150px;
  height: 150px;
`;
const Options = styled.div`
  display: flex;
  padding-top: 40px;
  align-items: center;
  justify-content: center;
`;
const ResponsiveDiv = styled.div`
  width: 50%;
  box-sizing: border-box;
  @media (max-width: 700px) {
    width: 100%;
    padding: 10px 0 !important;
  }
`;
const MainContainer = styled.div`
  background-color: ${props => props.theme.grayBackground};
  height: 100%;
  width: 100%;
`;
class AddAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gettingData: 0,
      access_token: '',
      institutionName: '',
      addedInstitutions: [],
      plaidTokensObject: []
    };
    this.usersCollection = fire.firestore().collection('users')
    this.institutionsCollection = fire.firestore().collection('institutions')
  }
  handleOnSuccess = (token) => {
    this.setState({
      gettingData: 1
    });
    this.getAccessToken(token)
  }
  handleOnExit() {
    // console.log('errir')
    // handle the case when your user exits Link
  }
  componentDidMount() {
    let _this = this;
    this.usersCollection.doc(fire.auth().currentUser.uid).get().then(function(doc) {
      let institutionsNames = [];
      if(!doc.data().plaidTokens){
        return;
      }
      doc.data().plaidTokens.forEach((object) => {
        institutionsNames.push(object.name)
      });
      _this.setState({
        addedInstitutions: institutionsNames,
        plaidTokensObject: doc.data().plaidTokens
      });
    })
  }
  getAccessToken = (plaidPublicToken) => {
    let _this = this;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/get_access_token',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          public_token: plaidPublicToken
        }
      }).then((response) => {
        _this.setState({
          access_token: response.data.access_token
        }, _this.getItemData());
      }).catch(() => {
        // console.log('error', error.response)
      })
    })
  };
  handleOnEvent = (event, metadata) => {
    let institutionAdded = false
    if(event === 'SELECT_INSTITUTION' && this.state.addedInstitutions.indexOf(metadata.institution_name) > -1) {
        institutionAdded = true
    }
    if(institutionAdded) {
      this._plaidComponent.exit({ force: true })
      window.alert('Institution already added');
    }
  };

  getItemData = () => {
    let _this = this
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/item',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          access_token: _this.state.access_token
        }
      }).then((response) => {
        if(response.data.error) {
          window.alert('Something went wrong');
          return;
        }
        if(_this.state.addedInstitutions.indexOf(response.data.institution.name) > -1){
          _this.props.history.push('/accounts')
          return;
        }
        const {plaidTokensObject} = _this.state
        let newPlaidTokens = plaidTokensObject
        newPlaidTokens.push({
          name: response.data.institution.name,
          access_token: _this.state.access_token
        })
        _this.usersCollection.doc(fire.auth().currentUser.uid).update({
          plaidTokens: newPlaidTokens
        });
        _this.getTransactions(response.data.institution.name, response.data.institution.logo)
        // console.log(response, 'itemm')
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
      })
    })
  }
  getTransactions = (institutionName, institutionLogo) => {
    let _this = this
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/transactions',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          access_token: _this.state.access_token
        }
      }).then((response) => {
        if(response.data.error) {
          window.alert('Something went wrong')
          return;
        }
        let accounts = response.data.transactions.accounts;
        let transactions = response.data.transactions.transactions;
        accounts.forEach((account) => {
          let accountTransactions = [];
          transactions.forEach((transaction) => {
            if(transaction.account_id === account.account_id){
              accountTransactions.push(transaction)
            }
          });
          account.transactions = accountTransactions;
        });
        _this.institutionsCollection.add({
          user_id: fire.auth().currentUser.uid,
          name: institutionName,
          accounts: accounts,
          lastUpdated: moment().format('YYYY-MM-DD, HH:mm'),
          access_token: _this.state.access_token,
          recurringPaymentsDetected: false,
          miscAdded: false,
          logo: institutionLogo
        }).then(() => {
          _this.props.history.push('/accounts')
        })
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
      })
    })
  }
  render(){
    return (
      <MainContainer>
        {this.state.gettingData ?
          <LoadingScreen/>
          :
          <MainWidthContainer>
            <TitleOnGray>Add accounts</TitleOnGray>
            <SubtitleOnGray>Select account type</SubtitleOnGray>
            <Options>
              <ResponsiveDiv style={{paddingRight: '10px'}}>
                <PlaidLink
                  ref={(component)=>{this._plaidComponent = component}}
                  style={{width: '100%', padding: 0, border: 'none'}}
                  clientName="Your app name"
                  env="sandbox"
                  product={["auth", "transactions"]}
                  publicKey="51379b83e0f2627df43dfccd06c6a2"
                  onEvent={this.handleOnEvent}
                  onExit={this.handleOnExit}
                  onSuccess={this.handleOnSuccess}>
                  <AccountTypeOption>
                    <OptionImage image={Images.grayLogo}/>
                    <BoxTitle>BANK ACCOUNT</BoxTitle>
                    <BoxDescription style={{maxWidth: '180px'}}>For people who just started building their
                      assets</BoxDescription>
                  </AccountTypeOption>
                </PlaidLink>
              </ResponsiveDiv>
              {/*<ResponsiveDiv style={{paddingLeft: '10px'}}>*/}
                {/*<AccountTypeOption>*/}
                  {/*<OptionImage image={Images.grayLogo}/>*/}
                  {/*<BoxTitle>INVESTMENTS</BoxTitle>*/}
                  {/*<BoxDescription style={{maxWidth: '180px'}}>For people who just started building their*/}
                    {/*assets</BoxDescription>*/}
                {/*</AccountTypeOption>*/}
              {/*</ResponsiveDiv>*/}
            </Options>
            <GrayLogo style={{margin: '0 auto', marginTop: '50px'}}/>
          </MainWidthContainer>
        }
      </MainContainer>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
AddAccounts.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AddAccounts));
