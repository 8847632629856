import React from 'react';
import styled from 'styled-components';
import {Title, Subtitle} from "../components/TextElements";
import Images from '../images/Images'
import {FullLogo, GrayLogo} from '../components/Logos';
import {withRouter} from 'react-router-dom';
import SelectPlanBox from '../components/SelectPlanBox';
import PlanOverviewBox from '../components/PlanOverviewBox';
import CreditCardInput from '../containers/CreditCardInput';
import fire from '../config/fire';
import axios from 'axios';
import ErrorText from '../components/ErrorText';


const VerifyContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
`;
const LeftSideImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegisterFormContainer = styled.div`
  width: 350px;
  padding: 20px 0;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 10px;
`;
const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const SelectPlanContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-top: 20px;
  flex-flow: wrap;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;
const FirstStepContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding-top: 20px;
  background-color:${props => props.theme.grayBackground};
  padding-bottom:40px;
`;
const MasterContainer = styled.div`
  height: ${props=>props.step ? '100%' : 'initial'};
  background-color: ${props=>props.step ? 'white' : '#f6f6f7'};
`;
const TitleAndSubtitleContainer = styled.div`
  @media (max-width: 900px) {
    margin-left: 2.5%;
  }
`;
const plans = {
  'basic_plan':{
    title:'BASIC',
    price: '4.99'
  },
  'premium_plan': {
    title: 'PREMIUM',
    price: '9.99'
  },
  'advanced_plan': {
    title: 'ADVANCED',
    price:'14.99'
  }
};
class SelectPlan extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      plan:'start_plan',
      step:0,
      serverError:''
    };
    let _this = this;
    fire.firestore().collection('users').doc(fire.auth().currentUser.uid).get().then(function(doc) {
      if (doc.data().activeSubscription) {
        _this.props.history.push('/dashboard')
      }
    }).catch(() => {
      // console.log('error', error)
    })
    this.cardInput = React.createRef();
  }
  selectPlan = (plan) => {
    this.setState({
      plan:plan
    })
  };
  goToPayment = (plan) => {
    this.setState({
      plan: plan,
      step:1
    });
  };
  changePlan = () => {
    this.setState({
      step:0
    })
  };
  componentDidMount(){

  }
  stripeTokenCallback = (token) => {
    this.setState({
      serverError:''
    });
    this.createStripeCustomer(token.id)
  };
  createStripeCustomer = (stripeTokenId) => {
    let _this = this;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/createCustomer',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          stripeToken: stripeTokenId
        }
      }).then(() => {
        // console.log(response)
        _this.subscribeToPlan(auth)
      }).catch((error) => {
        _this.setState({
          serverError: error.response.data.error.message
        });
        _this.cardInput.current.setSendingToServer(false);
        // console.log('error', error.response)
      })
    })
  };
  subscribeToPlan = (auth) => {
    let _this = this;
    axios({
      method: 'post',
      url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/subscribeToPlan',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth,
      },
      data: {
        plan: this.state.plan,
      }
    }).then(() => {
      // console.log('subscribed successfully',response)
      _this.props.history.push('/dashboard');
    }).catch((error) => {
      _this.setState({
        serverError: error.response.data.error.message
      });
      _this.cardInput.current.setSendingToServer(false);
      // console.log('error', error.response)
    })
  }
  render(){
    const {step} = this.state;
    return (
      <MasterContainer step={step}>
        {!step ?
          <FirstStepContainer>
            <TitleAndSubtitleContainer>
              <Title>
                Select Plan
              </Title>
              <Subtitle>Step 3 of 3</Subtitle>
            </TitleAndSubtitleContainer>
            <SelectPlanContainer>
              <SelectPlanBox
                title="BASIC"
                description="For people who just started building their assets"
                price="4.99"
                benefits={['Manage account balances','Banking consolidation','Budget tracking','Bill management','Connect with an advisor']}
                selected={this.state.plan === 'basic_plan'}
                onClick={() => this.selectPlan('basic_plan')}
                goToPayment={() => this.goToPayment('basic_plan')}
              />
              <SelectPlanBox
                title="PREMIUM"
                description="For people who just started building their assets"
                price="9.99"
                benefits={['Manage account balances','Banking consolidation','Budget tracking','Bill management', 'Monitor your net worth', 'Manually maintain your investments and other assets','Connect with an advisor']}
                selected={this.state.plan === 'premium_plan'}
                onClick={() => this.selectPlan('premium_plan')}
                goToPayment={() => this.goToPayment('premium_plan')}
                disabled
              />
              <SelectPlanBox
                title="ADVANCED"
                description="For people who just started building their assets"
                price="14.99"
                benefits={['Manage account balances','Banking consolidation','Budget tracking','Bill management', 'Track your net worth', 'Connect and monitor your investment accounts','Manually enter and track other assets','Connect with an advisor']}
                selected={this.state.plan === 'advanced_plan'}
                onClick={() => this.selectPlan('advanced_plan')}
                goToPayment={() => this.goToPayment('advanced_plan')}
                disabled
              />
            </SelectPlanContainer>
            <CenteredContainer>
              <GrayLogo style={{marginTop:'40px'}}/>
            </CenteredContainer>
          </FirstStepContainer>
          :
          <VerifyContainer>
            <LeftSideImage image={step ? Images.background4 : Images.background4}>
              <FullLogo/>
            </LeftSideImage>
            <RightSide>
              <div>
                  <Title>
                      Select Plan
                  </Title>
                  <Subtitle>Step 3 of 3</Subtitle>
                  <PlanOverviewBox
                    style={{marginTop:'20px'}}
                    title={plans[this.state.plan].title}
                    price={plans[this.state.plan].price}
                    changePlan={this.changePlan}
                  />
                <RegisterFormContainer>
                  <ErrorsContainer>
                    <ErrorText errorText={this.state.serverError}/>
                  </ErrorsContainer>
                 <CreditCardInput stripeTokenCallback={this.stripeTokenCallback} ref={this.cardInput}/>
                </RegisterFormContainer>
              </div>
            </RightSide>
          </VerifyContainer>
        }
      </MasterContainer>
    );
  }
}

export default withRouter(SelectPlan);
