import styled from 'styled-components';

export const FlexSpacer = styled.div`
  flex-grow: 1;
`;

export const GrayContainer = styled.div`
  background-color: ${props => props.theme.grayBackground};
  min-height: 100%;
  width: 100%;
`;

export const CloseContainer = styled.div`
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
`;

export const CloseIcon = styled.img`
  width: 26px;
  height: 26px;
  position: absolute;
  top: 35px;
  right: 0;
  cursor: pointer;
`;

export const MainWidthContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
  flex-direction: column;
  padding: 36px 15px;
`;
