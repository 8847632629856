import React from 'react';

import {TopNavBackground} from '../css/styled_components/NavBarComponents';
import NavBar from '../components/NavBar';
import PropTypes from "prop-types";

class TopNavbar extends React.Component {
    constructor(props){
      super(props)
      this.state={
        displayOnRoutes: [
          '/account-expanded',
          '/transactions',
          '/assets',
          '/assets-details/:'
        ],
        displayWithBackground: [
          '/accounts',
          '/dashboard',
          '/budget',
          '/net-worth',
        ]
      }
    }
    shouldDisplay = () => {
      return this.checkShouldDisplay(this.props.path, this.state.displayOnRoutes);
    };
    shouldDisplayWithBackground = () => {
      return this.checkShouldDisplay(this.props.path, this.state.displayWithBackground);
    };
    checkShouldDisplay = (path, arr) => {
      for (var i = 0; i < arr.length; i++) {
        if (!arr[i].includes(':') && arr[i] == path) {
          return true;
        } else if (arr[i].includes(':') && this.pathsMatch(arr[i], path)) {
          return true;
        }
      }
      return false;
    };
    pathsMatch = (first, second) => {
      let i = 0;
      while (first[i] == second[i]) {
        i++;
      }
      return first.substring(i) == ':' && second.substring(i).includes('/') == false;
    };
    render() {
        const isDisplayed = this.shouldDisplay();
        const isDisplayedWithBackground = this.shouldDisplayWithBackground();
        return (
          <React.Fragment>
            {isDisplayed || isDisplayedWithBackground ?
               isDisplayedWithBackground ?
               <React.Fragment>
                <TopNavBackground/>
                <NavBar path={this.props.path}/>
               </React.Fragment>
              :
              <NavBar path={this.props.path} isWithoutBackground={true}/>
              :
              <div/>
            }
          </React.Fragment>
        );
      }

}
TopNavbar.propTypes = {
  path:PropTypes.string
};
export default TopNavbar;
