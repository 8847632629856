// Set up your root reducer here...
//  import { combineReducers } from 'redux';
//  export default combineReducers;
import { combineReducers } from 'redux';
import { account } from './AccountReducer';
import { netWorth } from './NetWorthReducer';

const rootReducer = combineReducers({
  account,
  netWorth
});

export default rootReducer;
