
import React from 'react';
import styled from 'styled-components';

import PropTypes from "prop-types";
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import {RightCardArrow} from '../components/shared_components/Icons';

const AssetAccountsItemsTableRow = styled.tr`
    width: 100%;
    display: flex;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #F2F2F2;
    background-color: white;
    padding: 14px 24px 13px 24px;

td {
    padding: 0;
}
.td-image-adjust {
    height: 37px;
}
.asset-accounts-item-icon {
    height: 37px;
    width: 37px;
}
.asset-accounts-item-title {
    color: #454B54;
    font-family: "NotoSans-Regular", serif;
    font-size: 1rem;
    margin: 0;
    margin-left: 12px;
}
.asset-accounts-item-amount-button {
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
}
.asset-accounts-item-amount {
    font-family: "Noto Serif";
    font-size: 1.2rem;
    color: #454B54;
    margin: 0;
    font-weight: 600;
    text-align: right;
}
.asset-accounts-item-arrow {
    height: 12px;
    width: 22px;
}
`;

const NetWorthAssetsTableRow = (props) => {
    return (
    <AssetAccountsItemsTableRow>
        <td className="td-image-adjust">
            <img src={props.icon} className="asset-accounts-item-icon">
            </img>
            </td>
            <td>
                <p className="asset-accounts-item-title" >{props.title}</p>
            </td>
            <FlexSpacer/>
            <td>
                <button className="asset-accounts-item-amount-button" onClick={props.onClick}>
                    <p className="asset-accounts-item-amount">{props.amount}</p>
                    <svg viewBox="0 0 129 129" className="asset-accounts-item-arrow">{RightCardArrow}</svg>
                </button>
        </td>
    </AssetAccountsItemsTableRow>
    );
}

export default NetWorthAssetsTableRow;

NetWorthAssetsTableRow.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.string,
    onClick: PropTypes.func
};
