import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import '../css/navbar.css';
import {TopNavContainer, HomeLogoIconContainer, LinkContainer, IconLinkContainer, MobileAdjustSpacer, BottomMobileNav, MobileIconContainer, MobileIconText} from '../css/styled_components/NavBarComponents';
import {budgetIcon, bankingIcon, dashboardIcon} from '../images/icons/navIcons/navIcons';
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import logo from '../images/logo.png';

const NavBar = props => {

    const isActiveClass = {
      dashboard: props.path === '/dashboard',
      netWorth: props.path === '/net-worth',
      banking: props.path === '/accounts',
      investments: props.path === '/investments',
      budget: props.path === '/budget'
    };

    return (
      <React.Fragment>
        <TopNavContainer isWithoutBackground={props.isWithoutBackground}>
            <MobileAdjustSpacer/>
            <HomeLogoIconContainer>
              <Link to='/'><img src={logo} className='nb-icon' alt="Alto Logo"/></Link>
            </HomeLogoIconContainer>
                <LinkContainer><Link to='/'>Dashboard</Link></LinkContainer>
                {/*<LinkContainer><Link to='/net-worth'>Net Worth</Link></LinkContainer>*/}
                <LinkContainer><Link to='/accounts'>Banking</Link></LinkContainer>
                {/*<LinkContainer><Link to='/'>Investments</Link></LinkContainer>*/}
                <LinkContainer><Link to='/budget'>Budget</Link></LinkContainer>
                {/*<LinkContainer><Link to='/'>Calculator</Link></LinkContainer>*/}

        </TopNavContainer>
        <BottomMobileNav>
            {/* FOR MOBILE ICONS */}
            <IconLinkContainer><FlexSpacer/><Link to='/'><MobileIconContainer isActiveClass={isActiveClass.dashboard}>{dashboardIcon}<MobileIconText isActiveClass={isActiveClass.dashboard}>Dashboard</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>
            {/*<IconLinkContainer><FlexSpacer/><Link to='/net-worth'><MobileIconContainer isActiveClass={isActiveClass.netWorth}>{netWorthIcon}<MobileIconText isActiveClass={isActiveClass.netWorth}>Net Worth</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>*/}
            <IconLinkContainer><FlexSpacer/><Link to='/accounts'><MobileIconContainer isActiveClass={isActiveClass.banking}>{bankingIcon}<MobileIconText isActiveClass={isActiveClass.banking}>Banking</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>
            {/*<IconLinkContainer><FlexSpacer/><Link to='/'><MobileIconContainer isActiveClass={isActiveClass.investments}>{investmentsIcon}<MobileIconText isActiveClass={isActiveClass.investments}>Investments</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>*/}
            <IconLinkContainer><FlexSpacer/><Link to='/budget'><MobileIconContainer isActiveClass={isActiveClass.budget}>{budgetIcon}<MobileIconText isActiveClass={isActiveClass.budget}>Budget</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>
        </BottomMobileNav>
      </React.Fragment>

    );
}

NavBar.propTypes = {
  isWithoutBackground: PropTypes.bool,
  path: PropTypes.string,
};

export default NavBar;
