import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {netWorthActions} from '../actions/netWorthActions';

import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
import NetWorthAssetsTableRow from '../components/NetWorthAssetsTableRow';
import {TopTitlesContainer, RowContainer, BackArrowButton, BackArrowSvg, AssetsTitle} from '../css/styled_components/AssetsComponents';
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import {LeftBackArrowIcon} from '../components/shared_components/Icons';
// import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
// import icons from '../images/icons/icons';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';

import {formatAccountTotalWithZero, formatAccountTotalFullWithZero} from '../utils/accountsFormatting';
import {getNetWorthWithSavingAccounts} from '../data/NetWorth';
import {AssetCategory} from '../constants/AssetCategory';

const AssetWorthGrid = styled.div`
	border-radius: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #8739E5 0%, #5496FF 100%);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    height: 200px;

    .asset-worth-title {
        font-family: "NotoSans-Regular", serif;
        font-size: 1rem;
        margin: 0;
        color: rgba(255,255,255,0.4);
        text-align: center;
    }
  .asset-worth-amount {
        color: #FFFFFF;
        font-family: "Noto Serif Display";
        font-size: 2.6rem;
        font-weight: 600;
        margin: 12px 0 0;
        letter-spacing: 1.13px;
        text-align: center;
  }
`;

const AssetAccountsItemsTable = styled.table`
    display: block;
    background-color: white;
    margin: 24px 0 0;
		border-radius: 2px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
`;

class NetWorthAssets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categoriesWithAmounts: []
    }
  }
  componentDidMount() {
		if(!this.props.data.isSuccess) {
      this.props.actions.fetchNetWorthStart();
      getNetWorthWithSavingAccounts().then((data) => {
        this.props.actions.fetchNetWorthSuccess(data);
				this.updateCategories();
      }).catch((e) => {
        this.props.actions.fetchNetWorthFailure(e);
      })
    } else {
			this.updateCategories();
		}
  }
	updateCategories = () => {
		let categoriesWithAmounts = this.groupCategoriesWithAmounts(this.props.data.assets, this.props.data.assetsInAccountsTotal);
		this.setState({
			categoriesWithAmounts: categoriesWithAmounts
		});
	};
  groupCategoriesWithAmounts = (assets, assetsInAccountsTotal) => {
    let allCategories = Object.values(AssetCategory);
    let grouped = [];
    grouped[0] = AssetCategory.CHECKING_AND_SAVINGS_ACCOUNTS;
    grouped[0].amount = assetsInAccountsTotal;
    for (var i = 0; i < assets.length; i++) {
      var item = assets[i];
      var existingCat = grouped.find(cat => { return cat.dto === item.category });
      if (existingCat) {
        existingCat.amount += item.amount;
      } else {
        let newCat = allCategories.find(cat => { return cat.dto === item.category });
        newCat.amount = item.amount;
        grouped.push(newCat);
      }
    }
    return grouped;
  };
  openCategory = (category) => {
    if (category.id === AssetCategory.CHECKING_AND_SAVINGS_ACCOUNTS.id) {
      this.props.history.push('/accounts');
    } else {
      this.props.history.push('/assets-details/' + category.path);
    }
  };
  render(){
    return (
      <React.Fragment>
        <TopTitlesContainer>
          <FlexSpacer/>
          <div className="top-spacer-container">
          <BackArrowButton onClick={() => this.props.history.goBack()}>
            <BackArrowSvg viewBox="0 0 32 32">
                {LeftBackArrowIcon}
            </BackArrowSvg>
            <AssetsTitle>
                Assets
            </AssetsTitle>
          </BackArrowButton>
          <FlexSpacer/>
            <button className="plus-icon-button" onClick={() => this.props.history.push('/add-assets')}>
                +
            </button>
          </div>
          <FlexSpacer/>
        </TopTitlesContainer>
        <RowContainer>
          <FlexSpacer/>
          <div className="inner-spacer-container">

            <AssetWorthGrid>
                <FlexSpacer/>
                <div className="inner-container">
                    <p className="asset-worth-title">
                        Assets Worth
                    </p>
                    <h5 className="asset-worth-amount">
                        {formatAccountTotalWithZero(this.props.data.totalAssets)}
                    </h5>
                </div>
                <FlexSpacer/>
            </AssetWorthGrid>

            <AssetAccountsItemsTable>
              {this.state.categoriesWithAmounts.map((category, i) =>
                  <NetWorthAssetsTableRow key={i}
																					icon={category.icon}
                                          title={category.description}
                                          amount={formatAccountTotalFullWithZero(category.amount)}
                                          onClick={() => {this.openCategory(category)}} />
              )}
            </AssetAccountsItemsTable>
            </div>
          <FlexSpacer/>
        </RowContainer>
				<BottomLogoSection/>
      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(netWorthActions, dispatch) };
}
function mapStateToProps({netWorth}){
  return { data: netWorth};
}
NetWorthAssets.propTypes = {
	history: PropTypes.object,
	actions: PropTypes.object,
	data: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NetWorthAssets));
