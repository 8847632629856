import React from 'react';
class TermOfService extends React.Component {
  render(){
    return (
      <div>
        Terms
      </div>
    );
  }
}

export default TermOfService;
