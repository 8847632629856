import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {netWorthActions} from '../actions/netWorthActions';

import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import {MagnifyingGlass, RightCardArrow} from '../components/shared_components/Icons';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
// import NetWorthLineBarGrid from '../components/NetWorthLineBarGrid';

import {formatAccountTotalWithZero} from '../utils/accountsFormatting';
import {getNetWorthWithSavingAccounts} from '../data/NetWorth';

import { VictoryGroup, VictoryArea } from 'victory';

// Bar Colors GLOBAL
var ProgressBarGreen = "linear-gradient(270deg, #66BB6A 0%, #4DB6AC 100%)";
var ProgressBarPurple = "linear-gradient(134deg, #8739E5 0%, #5496FF 100%)";
var ProgressBarRed = "linear-gradient(134deg, #F5317F 0%, #FF7C6E 100%)";

const TopPageSpacerContainer = styled.div`
    display: block;
    margin-top: -180px;
    width: 100%;

    @media (min-width: 839px) {
        display: flex;
    }
`;

const GreyFlexSpacer = styled.div`
    flex-grow: 1;
    position: relative;
    background-color: #FAFAFB;
    z-index: -3;
`;

const PageSpacerContainer = styled.div`
    display: flex;
    background-color: #FAFAFB;
    width: 100%;
`;

const TopPageSpacerInnerContainer = styled.div`
    display: block;
    padding: 0 10%;

    @media (min-width: 839px) {
        padding: 0;
        width: 1025px;
    }
    .top-inner-container-flex {
        display: flex;
        align-items: center;
    }
    .magnifying-glass-button {
        background-color: transparent;
        border: none;
    }
    .magnifying-glass-icon {
        height: 26px;
        width: 26px;
    }
`;

const PageSpacerInnerContainer = styled.div`
    display: block;
    width: 100%;
    align-items: center;
    padding: 0 10%;
    background-color: '#FAFAFB';

    @media (min-width: 839px) {
      padding: 0;
      width: 1025px;
    }
`;

const NetWorthTitle = styled.h1`
    color: #FFFFFF;
    margin: 0;
    font-family: "NotoSans-Bold";
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 49px;
`;

const TotalBalanceGrid = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    margin: 30px 0 25px 0;
    height: 204px;
    width: 100%;
	border-radius: 2px;
    background: linear-gradient(180deg, #1B1E22 0%, #454B54 100%);
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.2);

    .total-balance-overlay {
        position: relative;
        z-index: 1;
    }
    .total-balance-title {
        color: rgba(255,255,255,0.4);
        font-family: "NotoSans-Regular", serif;
        font-size: 1rem;
        text-align: center;
        margin: 0 0 12px;
    }
    .total-balance-amount {
        color: #FFF;
        font-family: "Noto Serif Display", serif;
        font-size: 3.2rem;
        font-weight: 600;
        text-align: center;
        margin: 0;
        letter-spacing: 1.5px;
}`;

const LineBarGrid = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;

    .line-bar-grid-group-svg {
        width: 100%;
        height: 100%;
    }
`;

const ItemsProgressGrid = styled.div`
    width: 100%;
    background-color: white;
    padding: 18px 30px;
    margin-bottom: 18px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);

    .items-progress-grid-button {
        background-color: transparent;
        border: none;
        margin: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
    }
    .items-progress-grid-arrow {
        height: 12px;
        width: 24px;
    }
    .items-progress-grid-title {
        color: #454B54;
        font-family: "NotoSans-Regular", serif;
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
    }
    .items-progress-grid-amount {
        color: #454B54;
        font-family: "Noto Serif";
        font-size: 1.6rem;
        font-weight: 600;
        margin: 10px 0 0;
    }
`;

const ItemProgressBar = styled.progress`
    width: 100%;
    border-radius: 2px;
    height: 6px;
    background: #FFF;
    &::-webkit-progress-value { background: ${props => props.color}; }
    &::-moz-progress-bar { background: ${props => props.color} }

    &[value]::-webkit-progress-bar {
        background-color: #FFF;
}
`;

class NetWorth extends React.Component {
  componentDidMount() {
    this.props.actions.fetchNetWorthStart();
    getNetWorthWithSavingAccounts().then((data) => {
      this.props.actions.fetchNetWorthSuccess(data);
    }).catch((e) => {
      this.props.actions.fetchNetWorthFailure(e);
    })
  }
  render() {
    let assets = this.props.data.totalAssets;
    let liabilities = this.props.data.totalLiabilities;
    let assetBarPercentage = (assets >= liabilities) ? 100 : (assets / liabilities * 100);
    let liabilitiesBarPercentage = (assets >= liabilities) ? (liabilities / assets * 100) : 100;
    let netWorthBarPercentage = (assets >= liabilities) ? (assetBarPercentage - liabilitiesBarPercentage) : 0;
    return (
<React.Fragment>

    <TopPageSpacerContainer>
        <GreyFlexSpacer/>
        <TopPageSpacerInnerContainer>
            <div className="top-inner-container-flex">
                <NetWorthTitle>
                    Net Worth
                </NetWorthTitle>
                <FlexSpacer/>
                <button className="magnifying-glass-button">
                    <svg className="magnifying-glass-icon" viewBox="0 0 57 57">
                        {MagnifyingGlass}
                    </svg>
                </button>
            </div>

            <TotalBalanceGrid>

            <FlexSpacer/>
                <div className="total-balance-overlay">
                    <p className="total-balance-title">
                        Net Worth
                    </p>
                    <h1 className="total-balance-amount">
                        {formatAccountTotalWithZero(this.props.data.totalNetWorth)}
                    </h1>
                </div>
            <FlexSpacer/>


                <LineBarGrid>
                <svg style={{ height: 0, width: 0 }}>
                    <defs>
                    <linearGradient id="greenBackgroundGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor="#66BB6A"/>
                        <stop offset="100%" stopColor="4DB6AC"/>
                    </linearGradient>
                    </defs>
                </svg>
                    <svg preserveAspectRatio="none" viewBox="0 -150 450 450" className="line-bar-grid-group-svg">
                        <VictoryGroup
                        standalone={false}
                        domain={{ y: [0, 20], x: [0, 10]}}
                        domainPadding={0}
                        padding={0}
                        >
                            <VictoryArea
                                style={{
                                    data: {fillOpacity: "0.3", fill: "url(#greenBackgroundGradient)", stroke: "#5FBA7C", width: "100%"}
                                    }}
                                    padding={0}
                                    interpolation="natural"
                                data={[
                                    { x: 0, y: 1 },
                                    { x: 1, y: 8 },
                                    { x: 3, y: 2 },
                                    { x: 4, y: 2 },
                                    { x: 5, y: 9 },
                                    { x: 6, y: 3 },
                                    { x: 7, y: 4 },
                                    { x: 8, y: 15 },
                                    { x: 9, y: 1 },
                                    { x: 10, y: 4 }
                                    ]}/>

                        </VictoryGroup >
                    </svg>
                </LineBarGrid>

            </TotalBalanceGrid>

        </TopPageSpacerInnerContainer>
        <GreyFlexSpacer/>
    </TopPageSpacerContainer>


    <PageSpacerContainer>
        <FlexSpacer/>
        <PageSpacerInnerContainer>

            <ItemsProgressGrid>
                <button className="items-progress-grid-button" onClick={() => this.props.history.push('/assets')}>
                    <p className="items-progress-grid-title">Assets</p>
                    <svg className="items-progress-grid-arrow" viewBox="0 0 129 129">
                        {RightCardArrow}
                    </svg>
                </button>
                <ItemProgressBar color={ProgressBarPurple} value={assetBarPercentage} max="100"></ItemProgressBar>
                <h4 className="items-progress-grid-amount">{formatAccountTotalWithZero(this.props.data.totalAssets)}</h4>
            </ItemsProgressGrid>

            <ItemsProgressGrid>
            <button className="items-progress-grid-button">
                    <p className="items-progress-grid-title">Liabilities</p>
                    <svg className="items-progress-grid-arrow" viewBox="0 0 129 129">
                        {RightCardArrow}
                    </svg>
                </button>
                <ItemProgressBar color={ProgressBarRed} value={liabilitiesBarPercentage} max="100"></ItemProgressBar>
                <h4 className="items-progress-grid-amount">{formatAccountTotalWithZero(this.props.data.totalLiabilities)}</h4>
            </ItemsProgressGrid>

            <ItemsProgressGrid>
            <button className="items-progress-grid-button">
                    <p className="items-progress-grid-title">Net Worth (Assets - Liabilities)</p>
                    <svg className="items-progress-grid-arrow" viewBox="0 0 129 129">
                        {RightCardArrow}
                    </svg>
                </button>
                <ItemProgressBar color={ProgressBarGreen} value={netWorthBarPercentage} max="100"></ItemProgressBar>
                <h4 className="items-progress-grid-amount">{formatAccountTotalWithZero(this.props.data.totalNetWorth)}</h4>
            </ItemsProgressGrid>

        </PageSpacerInnerContainer>
        <FlexSpacer/>
    </PageSpacerContainer>

    <BottomLogoSection isBackgroundGrey={true}/>

</React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(netWorthActions, dispatch) };
}
function mapStateToProps(state) {
  return { data: state.netWorth };
}
NetWorth.propTypes = {
  history: PropTypes.object,
  data: PropTypes.object,
  actions: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NetWorth));
