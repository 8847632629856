// Set up your application entry point here...
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import './fonts/fonts.css';
const {store, persistor} = configureStore({});
import { PersistGate } from 'redux-persist/integration/react'

render(
  <AppContainer>
    <PersistGate loading={null} persistor={persistor}>
      <Root store={store} history={history} />
    </PersistGate>
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <PersistGate loading={null} persistor={persistor}>
          <NewRoot store={store} history={history} />
        </PersistGate>
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
