import styled from 'styled-components';

export const TopNavBackground = styled.div`
  position: relative;
  z-index: -1;
  height: 319px;	
  background: linear-gradient(0deg, #66BB6A 0%, #3DBCB0 100%);
`;
export const TopNavContainer = styled.div`
  position: absolute;
  top: 64px;
  height: 64px;
  width: 100%;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  
  @media (min-width: 839px) {
    padding: 0 26px;
  }

  ${({ isWithoutBackground }) => isWithoutBackground && `
      background-color: #40BBA9;
  `}
`;
export const MobileAdjustSpacer = styled.div`
    flex-grow: 1;

    @media (min-width: 839px) {
      display: none;
    }
`;
export const DesktopAdjustSpacer = styled.div`
  display: none;

  @media (min-width: 839px) {
    display: inherit;
    flex-grow: 1;
    }
`;

export const BottomMobileNav = styled.div`
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100%;
  background-color: #595F67;
  display: inline-flex;
  z-index: 10;

  @media (min-width: 839px) {
    display: none;
  }
`;

export const HomeLogoIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  position: absolute;
  left: 50px;
`;
export const ProfileLogoIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkContainer = styled.div`
display: none;

@media (min-width: 839px) {
  display: flex;
}

  a {
    padding: 23px 24px;
    border-radius: 0 0 2px 2px;	
    text-align: center;
    color: #FFF;	
    font-family: "NotoSans-Regular", serif;	
    line-height: 19px;	
    font-size: 1.1rem;	
    font-weight: 600;	
    letter-spacing: 0.44px;
    text-align: center;
  }
  &:hover {
    border-radius: 0 0 2px 2px;	
    background-color: rgba(255,255,255,0.12);
  }
`;

export const IconLinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

    @media (min-width: 839px) {
      display: none;
    }
`;

export const MobileIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11px;

    ${({ isActiveClass }) => isActiveClass && `
    padding-top: 8px;
    border-top: 3px solid green;
`}
`;

export const MobileIconText = styled.p`
    color: white;
    margin: 5px 0 0;
    display: block;
    text-align: center;

    ${({ isActiveClass }) => isActiveClass && `
    color: green;
`}
`;

export const ProfileIconImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

export const ArrowButton = styled.button`
    border: none;
    background-color: transparent;
    display: none; 

    @media (min-width: 839px) {
      display: block;
    }
`;
