const theme = {
  primaryColor:"#66BB6A",
  secondaryColor:'#454B54',
  thirdColor: "rgba(69,75,84,0.6)",
  errorColor: "#D7463C",
  lightText: "rgba(69,75,84,0.4)",
  grayBackground: '#f6f6f7;',
  defaultBackground: '#ffffff',
  inputBorder: 'rgba(69,75,84,0.2)',
  inputPlaceholder: 'rgba(69,75,84,0.3)',
  separator: '#F2F2F2',
  boxShadow: 'rgba(0,0,0,0.1)'
};
export default theme;
