import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {TitleOnGray, SubtitleOnGray, BigText} from "../components/TextElements";
import {GrayContainer, CloseContainer, CloseIcon, MainWidthContainer} from "../css/styled_components/SharedComponents";
import PrimaryInput from '../components/PrimaryInput';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
import Modal from '../components/Modal';
import AssetSavingAccount from '../components/modal/AssetSavingAccount';
import AssetRegular from '../components/modal/AssetRegular';
import {AssetCategory} from '../constants/AssetCategory';

const WhiteContainer = styled.div`
  background-color: ${props => props.theme.defaultBackground};
  width: 100%;
  margin-top: 36px;
  border-radius: 2px;
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
`;

const InputWrapper = styled.div`
  margin: 30px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 27px 25px;
  border-top: 1px solid ${props => props.theme.grayBackground};

  &:hover, &:active {
    cursor: pointer;
    background-color: #F9F9FA;
  }
`;

const ItemIcon = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 28px;
`;

class NetWorthAddAsset extends React.Component {
  constructor(props) {
    super(props);
    var types = Object.values(AssetCategory);
    this.state = {
      search: '',
      allCategories: types,
      filteredCategories: types,
      selectedCategory: null
    }
  }
  updateSearch = (e) =>  {
    var toSearch = e.target.value;
    this.setState({
      search: toSearch,
      filteredCategories: this.state.allCategories.filter(function(type) {
        return type.description.toLowerCase().includes(toSearch.toLowerCase());
      })
    });
  };
  selectCategory = (category) => {
    this.setState({
      selectedCategory: category
    })
  };
  handleCloseModal = () => {
    this.setState({
      selectedCategory: null
    })
  };
  openAccounts = () => {
    this.props.history.push('/accounts');
  };
  onClosePage = () => {
    this.props.history.goBack();
  };
  render() {
    const addSavingsModal = this.state.selectedCategory && this.state.selectedCategory.id == 1
      ? (<Modal onClose={this.handleCloseModal}>
          <AssetSavingAccount onOpenAccounts={this.openAccounts} onCancel={this.handleCloseModal}/>
        </Modal>) : null;

    const addRegularModal = (this.state.selectedCategory && this.state.selectedCategory.id != 1)
      ? (<Modal onClose={this.handleCloseModal}>
          <AssetRegular
            category={this.state.selectedCategory}
            onSuccess={() => {}}
            onClose={this.handleCloseModal}/>
        </Modal>) : null;

    return (
      <React.Fragment>
        <GrayContainer>
          <CloseContainer>
            <CloseIcon src={require('../images/close-gray.svg')} onClick={this.onClosePage}/>
          </CloseContainer>
          <MainWidthContainer>
            <TitleOnGray>Add Assets</TitleOnGray>
            <SubtitleOnGray>Select asset type</SubtitleOnGray>
            <WhiteContainer>
              <InputWrapper>
                <PrimaryInput large value={this.state.search} name="search"
                              onChange={(e) =>this.updateSearch(e)}
                              label="Search for asset type"
                              type="text"
                              validationtype="text"
                              fieldname="Search"
                              haserror="false" />
              </InputWrapper>

              {this.state.filteredCategories.map((item) =>
                <React.Fragment key={item.id}>
                  <ItemContainer onClick={() => this.selectCategory(item)}>
                    <ItemIcon src={item.icon}/>
                    <BigText style={{fontWeight: "600"}}>{item.description}</BigText>
                  </ItemContainer>
                </React.Fragment>
              )}

            </WhiteContainer>
          </MainWidthContainer>
          <BottomLogoSection/>
        </GrayContainer>
        {addSavingsModal}
        {addRegularModal}
      </React.Fragment>
    );
  }
}
NetWorthAddAsset.propTypes = {
  history: PropTypes.object
}
export default NetWorthAddAsset;
