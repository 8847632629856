import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {LeftBackArrowIcon, MenuIconButtonThree} from "../components/shared_components/Icons";
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import {formatAccountTotalFull, formatAccountTotal, formatDecimalAmount} from '../utils/accountsFormatting';
import PrimaryInput from '../components/PrimaryInput';


const AccountFlexContainer = styled.div`
    display: block;

    @media (min-width: 839px) {
    display: flex;
    }
`;

const ExpandedContainer = styled.div`
    width: 100%;
    padding: 0;    

    @media (min-width: 839px) {
      width: 1025px;
    }
`;

const TopSpacer = styled.div`
    height: 164px;
`;

const BotSpacer = styled.div`
    height: 60px;

    @media (min-width: 839px) {
        height: 120px;
    }
`;

const TopButtonsGrid = styled.div`
    display: flex;
`;
const BackToAccounts = styled.button`
    display: inline-flex;
    background-color: transparent;
    border: none;
    padding-left: 0px;

    &:focus {
        background-color: transparent;
    }
    .a-svg-back-arrow-icon {
        padding-top: 3px;
        width: 50px;
        height: 33px;
    }
    .a-accounts-back-button-title {
        margin: 0;
        color: #454B54;	
        font-family: "NotoSans-Regular", serif;		
        font-size: 1.6rem;	
        line-height: 33px;
    }
`;

const MenuIconButton = styled.button`
    background-color: transparent;
    border: none;

        &:focus {
        background-color: transparent;
    }
    .a-accounts-menu-three-icon {
        height: 20px;
        width: 20px;
    }
`;

const AccountMainCard = styled.div`
    margin: 26px 0 1px;
    padding: 20px 30px 30px 30px;
    border-radius: 2px;	
    background-color: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
    width: 100%;
    .a-account-main-icon-img {
        height: 50px;
        width: 50px;
    }
    .a-main-card-account-title-p {
        color: #454B54;
        font-family: "NotoSans-Regular",serif;
        font-size: 1rem;
        font-weight: bold;
        margin: 0 0 10px 0;
        line-height: 14px;
    }
    .a-main-card-account-ammount-h2 {
        color: #454B54;
        font-family: "NotoSans-Regular",serif;
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0 0 6px 0;
        letter-spacing: 0.5px;
        line-height: 19px;
    }
    .a-main-card-account-ammount-h2-decimals {
        font-size: .9rem;
    }
    .a-main-card-available-ammount-p {
        color: rgba(69,75,84,0.4);
        font-family: "NotoSans-Regular",serif;
        font-size: 1rem;
        margin: 0;
        line-height: 19px;
    }
    .a-sub-account-card-account-p {
            color: rgba(69,75,84,0.4);
            font-family: "NotoSans-Regular",serif;
            font-size: 1rem;
            margin: 0;
            line-height: 19px;
        }
`;
const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SubAccountCards = styled.div`
    align-items: center;
    text-align: left;
    border: none;
    border-bottom: 1px solid #F2F2F2;
    border-radius: 2px;	
    background-color: #FFFFFF;	
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
    width: 100%;
    display: flex;
    padding: 20px 30px;
        .a-sub-account-card-icon {
            height: 48px;	
            width: 48px;
        }
        .a-sub-account-items-container {
            margin-left: 30px;
        }
        .a-sub-account-card-title-h3 {
            color: #454B54;
            font-family: "NotoSans-Regular",serif;
            font-size: .9rem;
            font-weight: bold;
            margin: 0 0 6px 0;
            line-height: 14px;
        }
        .a-sub-account-card-account-p {
            color: rgba(69,75,84,0.4);
            font-family: "NotoSans-Regular",serif;
            font-size: 1rem;
            margin: 0;
            line-height: 19px;
        }
        .a-sub-account-amount-container {
            display: inline-flex;
            align-items: center;
        }
        .a-sub-account-card-amount-h3 {
            color: #454B54;
            font-family: "NotoSans-Regular",serif;
            font-size: 1rem;
            font-weight: bold;
            padding-right: 20px;
            letter-spacing: 0.5px;
            line-height: 19px;
            margin: 0;
        }
        .a-sub-account-card-amount-arrow-icon {
            height: 22px;
            width: auto;
        }
`;

class Transactions extends React.Component {
  constructor(props){
    super(props);
    this.state={
      searching: false,
      matchingItems: [],
      searchedItem:''
    }
  }
  onBackArrowClick = () => {
    this.props.history.goBack();
  };
  handleChange = (e) =>  {
    let searching = false
    if(e.target.value){
      searching = true
    }
    let matchingItems = [];
    this.props.currentAccount.transactions.forEach((transaction) => {
      if(transaction.name.toLowerCase().includes(e.target.value.toLowerCase()) || transaction.amount.toString().includes(e.target.value.toLowerCase())){
        matchingItems.push(transaction)
      }
    })
    this.setState({
      [e.target.name]: e.target.value,
      searching: searching,
      matchingItems: matchingItems
    });
  };
  formatTotalAmountHeader = (total) => {
    return (
      <h2 className="a-main-card-account-ammount-h2">
        <span>{this.props.currentAccount.type === 'credit' ? '-' : ''}{formatAccountTotal(total)}</span>
        <span className="a-main-card-account-ammount-h2-decimals">{formatDecimalAmount(total)}</span>
      </h2>
    );
  };
  render(){
    return (
<AccountFlexContainer>
  <FlexSpacer/>
      <ExpandedContainer>
        <TopSpacer/>

        <TopButtonsGrid>
          <BackToAccounts onClick={this.onBackArrowClick}>
            <svg className={"a-svg-back-arrow-icon"} viewBox="0 0 33 33">
              {LeftBackArrowIcon}
            </svg>
            <h2 className="a-accounts-back-button-title">{this.props.selectedAccount ? this.props.selectedAccount.name : 'acc name'}</h2>
          </BackToAccounts>
          <FlexSpacer/>
          <MenuIconButton>
            <svg className="a-accounts-menu-three-icon" viewBox="0 0 512 512">
              {MenuIconButtonThree}
            </svg>
          </MenuIconButton>
        </TopButtonsGrid>

        <AccountMainCard>
          <FirstSection>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img className="a-account-main-icon-img" src={`data:image/jpeg;base64,${this.props.currentLogo}`} alt="Main Bank Icon"/>
              <div style={{marginLeft: '20px'}}>
                <p className="a-main-card-account-title-p">{this.props.selectedAccount ? this.props.selectedAccount.name : 'acc name'}</p>
                <p className="a-sub-account-card-account-p">{this.props.currentAccount ? this.props.currentAccount.name : 'acc name'}</p>
              </div>
            </div>
            {this.formatTotalAmountHeader(this.props.currentAccount ? this.props.currentAccount.balances.current :200)}
          </FirstSection>
          <div style={{width: '100%'}}>
            <PrimaryInput
              value={this.state.searchedItem}
              name="searchedItem"
              onChange={(e) =>this.handleChange(e)}
              label="Search by merchant or amount"
              type="text"
              validationtype="required"
              fieldname="Searched Item"
              haserror={'false'}
            />
          </div>
        </AccountMainCard>
        {this.state.searching ?
          <div>
            {this.state.matchingItems.map((transaction, index) => {
              return (
                <SubAccountCards key={index}>
                  <div>
                    <h3 className="a-sub-account-card-title-h3" style={{margin: 0}}>{transaction.name}</h3>
                  </div>
                  <FlexSpacer/>
                  <div className="a-sub-account-amount-container">
                    <h3 className="a-sub-account-card-amount-h3">{formatAccountTotalFull(transaction.amount)}</h3>
                  </div>
                </SubAccountCards>
              );
            })
            }
          </div>
          :
          <div>
            {this.props.currentAccount.transactions.map((transaction, index) => {
              return (
                <SubAccountCards key={index}>
                  <div>
                    <h3 className="a-sub-account-card-title-h3" style={{margin: 0}}>{transaction.name}{transaction.pending ? '(pending)' : '' }</h3>
                  </div>
                  <FlexSpacer/>
                  <div className="a-sub-account-amount-container">
                    <h3 className="a-sub-account-card-amount-h3">{formatAccountTotalFull(transaction.amount)}</h3>
                  </div>
                </SubAccountCards>
              );
            })
            }
          </div>
        }
        <BotSpacer/>
      </ExpandedContainer>
      <FlexSpacer/>
    </AccountFlexContainer>
    );
  }
}
Transactions.propTypes = {
  props:PropTypes.object,
  actions:PropTypes.object,
  currentAccount:PropTypes.object,
  history:PropTypes.object,
  selectedAccount:PropTypes.object,
  currentLogo: PropTypes.string
};
function mapStateToProps({account}){
  return {
    currentAccount : account.currentTransactions,
    selectedAccount : account.selectedAccount,
    currentLogo: account.currentLogo
  };
}

export default connect(mapStateToProps)(withRouter(Transactions));
