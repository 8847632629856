import React from 'react';

export const budgetIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
<title>Budget</title>
<desc>Created with Sketch.</desc>
<defs>
    <path d="M16.7799465,17.154 C16.7829465,17.147 16.7859465,17.141 16.7889465,17.134 C17.0669465,16.505 17.8979465,16.363 18.3809465,16.852 L20.7529465,19.253 C21.9579465,17.775 22.7499465,15.949 22.9489465,13.949 L17.9869465,13.949 C17.4419465,13.949 16.9999465,13.507 16.9999465,12.962 L16.9999465,12.936 C16.9999465,12.391 17.4419465,11.949 17.9869465,11.949 L22.9489465,11.949 C22.4789465,7.232 18.7169465,3.47 13.9999465,3 L13.9999465,4.962 C13.9999465,5.507 13.5579465,5.949 13.0129465,5.949 L12.9869465,5.949 C12.4419465,5.949 11.9999465,5.507 11.9999465,4.962 L11.9999465,3 C6.50494655,3.548 2.30494655,8.562 3.09594655,14.35 C3.69594655,18.741 7.25694655,22.282 11.6509465,22.86 C14.5769465,23.245 17.3009465,22.355 19.3469465,20.671 L16.9679465,18.258 C16.6779465,17.965 16.6109465,17.529 16.7799465,17.154 Z M15.6119465,15.519 C15.6119465,17.007 14.6729465,17.578 13.9999465,17.803 L13.9999465,18.949 C13.9999465,19.501 13.5519465,19.949 12.9999465,19.949 L12.9999465,19.949 C12.4479465,19.949 11.9999465,19.501 11.9999465,18.949 L11.9999465,17.863 C11.3059465,17.668 9.99994655,17.032 9.99994655,14.949 L11.8729465,14.949 C11.8729465,16.495 12.7459465,16.566 12.8789465,16.566 C13.0249465,16.566 13.7389465,16.542 13.7389465,15.531 C13.7389465,13.513 10.3109465,14.409 10.3109465,11.384 C10.3109465,9.858 11.3279465,9.283 11.9999465,9.069 L11.9999465,7.949 C11.9999465,7.397 12.4479465,6.949 12.9999465,6.949 L12.9999465,6.949 C13.5519465,6.949 13.9999465,7.397 13.9999465,7.949 L13.9999465,9.134 C14.6349465,9.424 15.6249465,10.175 15.6249465,11.949 L13.7519465,11.949 C13.7519465,10.706 13.1739465,10.34 12.9309465,10.34 C12.7979465,10.34 12.1829465,10.372 12.1829465,11.392 C12.1839465,13.173 15.6119465,12.618 15.6119465,15.519 Z" id="budget-path-1"/>
</defs>
<g id="budget-dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="M2.-Dashboard" transform="translate(-328.000000, -622.000000)">
        <g id="Tab-bar" transform="translate(0.000000, 613.000000)">
            <g id="budget-group-8">
                <g id="Assets-/-Icons-/-Tabs-/-Budget" transform="translate(325.000000, 6.000000)">
                    <mask id="budget-mask-2" fill="white">
                        <use xlinkHref="#budget-path-1"/>
                    </mask>
                    <g id="budget-shape" fillRule="nonzero"/>
                    <g id="Colors-/-White" mask="url(#budget-mask-2)" fill="#FFFFFF" fillRule="evenodd">
                        <rect id="budget-rectangle" x="0" y="0" width="26" height="26"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>;



export const bankingIcon = <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Banking</title>
    <defs>
        <path d="M13,4 C12.82,4 12.63975,4.0355156 12.46875,4.1035156 L3.9003906,7.53125 C3.3563906,7.74825 3,8.274375 3,8.859375 L3,9.390625 C3,10.390625 4,10.390625 4,10.390625 L4,20.390625 C3.448,20.390625 3,20.838625 3,21.390625 C3,21.942625 3.448,22.390625 4,22.390625 L22,22.390625 C22.552,22.390625 23,21.942625 23,21.390625 C23,20.838625 22.552,20.390625 22,20.390625 L22,10.390625 C22,10.390625 23,10.390625 23,9.390625 L23,8.859375 C23,8.274375 22.643609,7.74825 22.099609,7.53125 L13.53125,4.1035156 C13.36125,4.0345156 13.18,4 13,4 Z M6,10.390625 L8,10.390625 L8,20.390625 L6,20.390625 L6,10.390625 Z M10,10.390625 L12,10.390625 L12,20.390625 L10,20.390625 L10,10.390625 Z M14,10.390625 L16,10.390625 L16,20.390625 L14,20.390625 L14,10.390625 Z M18,10.390625 L20,10.390625 L20,20.390625 L18,20.390625 L18,10.390625 Z" id="banking-path-1"></path>
    </defs>
    <g id="banking-dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="M2.banking-dashboard" transform="translate(-178.000000, -623.000000)">
            <g id="Tab-bar" transform="translate(0.000000, 613.000000)">
                <g id="Group-8">
                    <g id="Assets-/-Icons-/-Tabs-/-University" transform="translate(175.000000, 6.000000)">
                        <mask id="banking-mask-2" fill="white">
                            <use xlinkHref="#banking-path-1"></use>
                        </mask>
                        <g id="banking-shape" fillRule="nonzero"></g>
                        <g id="Colors-/-White" mask="url(#banking-mask-2)" fill="#FFFFFF" fillRule="evenodd">
                            <rect id="banking-rectangle" x="0" y="0" width="26" height="26"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>;

export const netWorthIcon = <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Net worth</title>
    <defs>
        <path d="M13,3 C12.115084,3 11.328373,3.3906671 10.7792969,4 L9.0019531,4 C8.3189531,4 7.8367344,4.6694063 8.0527344,5.3164062 L8.4980469,6.6582031 C8.5660469,6.8622031 8.7596094,7 8.9746094,7 L13,7 L17.025391,7 C17.240391,7 17.432,6.8622031 17.5,6.6582031 L17.947266,5.3164062 C18.164266,4.6694062 17.681047,4 16.998047,4 L15.220703,4 C14.671627,3.3906671 13.884916,3 13,3 Z M9,9 C9,9 3,12.014 3,19 C3,19.835 3.102875,20.576375 3.296875,21.234375 C3.787875,22.898375 5.3724219,24 7.1074219,24 L18.892578,24 C20.627578,24 22.212125,22.898375 22.703125,21.234375 C22.897125,20.576375 23,19.835 23,19 C23,12.015 17,9 17,9 L9,9 Z M13.320312,10.7128906 L13.326172,10.7128906 C13.578172,10.7128906 13.783203,10.9179219 13.783203,11.1699219 L13.783203,12.041016 C14.026203,12.087016 15.193937,12.352594 15.585938,13.808594 C15.729938,14.344594 15.313766,14.869141 14.759766,14.869141 C14.363766,14.869141 14.013688,14.595984 13.929688,14.208984 C13.786687,13.541984 13.425375,13.419922 13.234375,13.419922 C13.045375,13.419922 12.546875,13.450516 12.546875,14.478516 C12.546875,16.272516 15.701172,15.713672 15.701172,18.638672 C15.701172,20.762672 13.957438,21.048891 13.648438,21.087891 L13.648438,21.84375 C13.648438,22.09575 13.443406,22.300781 13.191406,22.300781 C12.939406,22.300781 12.734375,22.09575 12.734375,21.84375 L12.734375,21.087891 C12.493375,21.054891 11.08125,20.898094 10.65625,19.246094 C10.51925,18.714094 10.9373281,18.199219 11.486328,18.199219 L11.488281,18.199219 C11.882281,18.199219 12.238312,18.465563 12.320312,18.851562 C12.509313,19.738563 13.083547,19.693359 13.185547,19.693359 C13.319547,19.693359 13.978516,19.668391 13.978516,18.650391 C13.978516,16.616391 10.8222656,17.519703 10.8222656,14.470703 C10.8222656,12.380703 12.554281,12.054625 12.863281,12.015625 L12.863281,11.1699219 C12.863281,10.9179219 13.068312,10.7128906 13.320312,10.7128906 Z" id="netWorth-path-1"></path>
    </defs>
    <g id="netWorth-dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="M2.netWorth-dashboard" transform="translate(-103.000000, -622.000000)">
            <g id="Tab-bar" transform="translate(0.000000, 613.000000)">
                <g id="netWorth-group-8">
                    <g id="Assets-/-Icons-/-Tabs-/-Net-Worth" transform="translate(100.000000, 6.000000)">
                        <mask id="netWorth-mask-2" fill="white">
                            <use xlinkHref="#netWorth-path-1"></use>
                        </mask>
                        <g id="Shape" fillRule="nonzero"></g>
                        <g id="Colors-/-White" mask="url(#netWorth-mask-2)" fill="#FFFFFF" fillRule="evenodd">
                            <rect id="netWorth-rectangle" x="0" y="0" width="26" height="26"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

export const dashboardIcon = <svg width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Dashboard</title>
    <defs>
        <path d="M13,4 C12.7328816,4.00003426 12.4768796,4.10693611 12.289062,4.296875 L2.203125,13.097656 C2.07538148,13.1919178 1.9999939,13.3412433 2,13.5 C2,13.7761424 2.22385763,14 2.5,14 L5,14 L5,22 C5,22.552 5.448,23 6,23 L10,23 C10.552,23 11,22.552 11,22 L11,16 L15,16 L15,22 C15,22.552 15.448,23 16,23 L20,23 C20.552,23 21,22.552 21,22 L21,14 L23.5,14 C23.7761424,14 24,13.7761424 24,13.5 C24.0000061,13.3412433 23.9246185,13.1919178 23.796875,13.097656 L13.716797,4.3027344 C13.7148521,4.30077318 13.7128991,4.29882003 13.710938,4.296875 C13.5231204,4.10693611 13.2671184,4.00003426 13,4 Z" id="dashboard-path-1"></path>
    </defs>
    <g id="dashboard-dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="M2.dashboard-dashboard" transform="translate(-27.000000, -623.000000)">
            <g id="Tab-bar" transform="translate(0.000000, 613.000000)">
                <g id="Group-8">
                    <g id="Assets-/-Icons-/-Tabs-/-University-Copy" transform="translate(25.000000, 6.000000)">
                        <mask id="dashboard-mask-2" fill="white">
                            <use xlinkHref="#dashboard-path-1"></use>
                        </mask>
                        <g id="Path" fillRule="nonzero"></g>
                        <g id="Colors-/-Green" mask="url(#dashboard-mask-2)" fill="#66BB6A" fillRule="evenodd">
                            <rect id="Rectangle" x="0" y="0" width="26" height="26"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

export const investmentsIcon = <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Investments</title>
    <defs>
        <path d="M14.748047,3 C14.082047,3 13.749703,3.8043906 14.220703,4.2753906 L15.265625,5.3203125 L14,6.5859375 L11.707031,4.2929688 C11.3165012,3.9026006 10.6834986,3.9026006 10.2929688,4.2929688 L7.2929688,7.2929688 C7.03173388,7.54378671 6.92650357,7.91623428 7.01786119,8.26667271 C7.1092188,8.61711113 7.38288887,8.8907812 7.73332729,8.98213881 C8.08376572,9.07349643 8.45621329,8.96826612 8.7070312,8.7070312 L11,6.4140625 L13.292969,8.7070312 C13.6834988,9.09739926 14.3165012,9.09739926 14.707031,8.7070312 L16.679688,6.734375 L17.722656,7.7792969 C18.194656,8.2512969 19,7.9179531 19,7.2519531 L19,4.0097656 C19,3.4527656 18.547234,3 17.990234,3 L14.748047,3 Z M4,11 C3.448,11 3,11.448 3,12 L3,22 C3,22.552 3.448,23 4,23 L22,23 C22.552,23 23,22.552 23,22 L23,12 C23,11.448 22.552,11 22,11 L4,11 Z M5.9121094,13 L20.087891,13 C20.2390014,13.4259145 20.5740855,13.7609986 21,13.912109 L21,20.087891 C20.5740855,20.2390014 20.2390014,20.5740855 20.087891,21 L5.9121094,21 C5.76099894,20.5740854 5.42591463,20.2390013 5,20.087891 L5,13.912109 C5.42591463,13.7609987 5.76099894,13.4259146 5.9121094,13 Z M13,14 C11.3431458,14 10,15.3431458 10,17 C10,18.6568542 11.3431458,20 13,20 C14.6568542,20 16,18.6568542 16,17 C16,15.3431458 14.6568542,14 13,14 Z M8,16 C7.448,16 7,16.447 7,17 C7,17.553 7.448,18 8,18 C8.552,18 9,17.553 9,17 C9,16.447 8.552,16 8,16 Z M18,16 C17.448,16 17,16.447 17,17 C17,17.553 17.448,18 18,18 C18.552,18 19,17.553 19,17 C19,16.447 18.552,16 18,16 Z" id="investments-path-1"></path>
    </defs>
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="M2.-Dashboard" transform="translate(-250.000000, -622.000000)">
            <g id="Tab-bar" transform="translate(0.000000, 613.000000)">
                <g id="Group-8">
                    <g id="Assets-/-Icons-/-Tabs-/-Investments" transform="translate(247.000000, 6.000000)">
                        <mask id="investments-mask-2" fill="white">
                            <use xlinkHref="#investments-path-1"></use>
                        </mask>
                        <g id="Shape" fillRule="nonzero"></g>
                        <g id="Colors-/-White" mask="url(#investments-mask-2)" fill="#FFFFFF" fillRule="evenodd">
                            <rect id="Rectangle" x="0" y="0" width="26" height="26"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>