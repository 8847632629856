import styled from "styled-components";
import Images from '../images/Images'
export const FullLogo = styled.div`
  background-image: url(${Images.fullLogo});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 25px;
  width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GrayLogo = styled.div`
  background-image: url(${Images.grayLogo});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GreenLogo = styled.div`
  background-image: url(${Images.logo_green});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 30px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
