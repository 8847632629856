import React from 'react';
import {Elements} from 'react-stripe-elements';

import PaymentButtonInjectedForm from '../components/PaymentButtonCheckoutForm';

class PaymentButton extends React.Component {
  render() {
    return (
      <Elements>
        <PaymentButtonInjectedForm />
      </Elements>
    );
  }
}

export default PaymentButton;
