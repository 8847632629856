import React from 'react';
import {injectStripe} from 'react-stripe-elements';

import CardSection from './CardSection';
import PrimaryButton from './PrimaryButton';
import ButtonLoader from './ButtonLoader';
import PropTypes from "prop-types";

class CheckoutForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      errors: {
        number: '',
        expiry: '',
        cvc: ''
      },
      sendingToServer: false
    }
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  setSendingToServer = (state) => {
    this.setState({
      sendingToServer: state
    })
  };
  handleSubmit = (ev) => {
    ev.preventDefault();
    let _this = this;
    if(this.state.errors.number !== '' || this.state.errors.expiry !=='' || this.state.errors.cvc !== ''){
      return;
    }
    this.setState({
      sendingToServer: true
    });
    this.props.stripe.createToken().then((result) => {
      if(result.token) {
        _this.setState({
          errors: {
            number: '',
            expiry: '',
            cvc: ''
          }
        });
        this.props.stripeTokenCallback(result.token);
        // console.log('Received Stripe token:', result.token);
      }
      else{
        _this.setState({
          sendingToServer: false
        });
        if(result.error.code.includes('number')){
          _this.setState({
            errors: { ...this.state.errors, number: result.error.message}
          });
        }
        if(result.error.code.includes('expiry')){
          _this.setState({
            errors: { ...this.state.errors, expiry: result.error.message}
          });
        }
        if(result.error.code.includes('cvc')){
          _this.setState({
            errors: { ...this.state.errors, cvc: result.error.message}
          });
        }
      }
    })
  };
  setErrors = (error,field) => {
    this.setState({
      errors: { ...this.state.errors, [field]: error}
    });
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <CardSection errors={this.state.errors} setErrors={this.setErrors}/>
        {this.state.sendingToServer ?
          <ButtonLoader style={{marginTop: "2rem"}} loading={this.state.sendingToServer}/>
          :
          <PrimaryButton style={{marginTop: "2rem"}}>Subscribe</PrimaryButton>
        }
      </form>
    );
  }
}
CheckoutForm.propTypes = {
  stripe: PropTypes.object,
  stripeTokenCallback: PropTypes.func,
  onRef: PropTypes.func
};
export default injectStripe(CheckoutForm);
