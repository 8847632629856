import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../components/PrimaryButton';
import PrimaryInput from '../components/PrimaryInput';
import {Title, Subtitle, ActionText, ActionPrimaryText,BigText} from "../components/TextElements";
import Images from '../images/Images'
import isValid from '../utils/inputValidator';
import ErrorText from '../components/ErrorText';
import {FullLogo, GrayLogo} from '../components/Logos';
import PhoneNumberInput from '../components/PhoneNumberInput';
import { isValidPhoneNumber } from 'react-phone-number-input'
import fire from '../config/fire';
import firebase from 'firebase';
import ButtonLoader from '../components/ButtonLoader'
import {withRouter} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const VerifyContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
`;
const LeftSideImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegisterFormContainer = styled.div`
  width: 350px;
  padding: 20px 0;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 10px;
`;
const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const EmailVerificationSentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 400px;
`;
class VerifyPhone extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      phoneNumber:'',
      verificationCode: '',
      error: {
        phoneNumber:'',
        verificationCode: ''
      },
      liveValidation: {
        phoneNumber:false,
        verificationCode: false,
      },
      hasErrors: 0,
      step:0,
      sendingToServer: false,
      verificationEmailSent: false,
      emailVerificationError: ''
    };
    this.confirmResult = null;
  }
  componentDidMount(){
    if(fire.auth().currentUser.phoneNumber){
      this.props.history.push('/select-plan')
    }
    // fire.auth().currentUser.unlink('phone').then(function() {
    //   // Auth provider unlinked from account
    // }).catch(function(error) {
    //   // An error happened
    // });
  }
  handleChange = (e, validate) =>  {
    this.setState({ [e.target.name]: e.target.value });
    if (validate) {
      let validationTypeString = e.target.getAttribute('validationtype');
      let validationArray = validationTypeString.split('|');
      validationArray.forEach((validationType) =>{
        if(!isValid(e.target.value,validationType)) {
          let errorMessage = '';
          if(validationType === 'required'){
            errorMessage = e.target.getAttribute('fieldname') + ' is required';
          }
          this.setState({ error: { ...this.state.error, [e.target.name]: errorMessage} });
        } else {
          this.setState({ error: { ...this.state.error, [e.target.name]: ''} });
        }
      });
    }
  };
  sendCode = () => {
    if(this.checkIfPhoneIsValid()) {
      this.setState({
        sendingToServer: true
      });
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function () {
          // console.log('intra aici', window.recaptchaVerifier)
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      });
      let phoneNumber = this.state.phoneNumber;
      let appVerifier = window.recaptchaVerifier;
      fire.auth().currentUser.linkWithPhoneNumber(phoneNumber, appVerifier).then((confirmationResult) => {
        // At this point SMS is sent. Ask user for code
        this.confirmResult = confirmationResult;
        // console.log('confirm 1', confirmationResult)
        this.goToVerificationCode();
      }).catch((error) => {
        this.setState({
          sendingToServer: false
        }, () => this.alertError(error.message));
      });
    }
  };
  goToVerificationCode = () => {
    // console.log('confirm2',this.confirmResult)
    this.setState({
      step:1,
      sendingToServer: false
    })
  };
  alertError = (error) => {
    setTimeout(() => {
      window.alert(error)
    },1000);
  };
  verifyCode = () => {
    let _this = this;
    if(this.state.verificationCode.length){
      _this.setState({ error: { ...this.state.error, verificationCode: ''},
        sendingToServer: true
      });
      this.confirmResult.confirm(this.state.verificationCode) .then(() => {
        // console.log('result', result)
        _this.props.history.push('/select-plan');
      }).catch((error) =>{
        let message = 'Verification code is invalid';
        if(error.code === 'auth/credential-already-in-use') {
          message = 'This phone number is currently tied to another account'
        }
        _this.setState({ error: { ...this.state.error, verificationCode: message},
                        liveValidation: {...this.state.liveValidation, verificationCode : true},
                        sendingToServer: false
      });
      });
    }
    else {
      _this.setState({ error: { ...this.state.error, verificationCode: 'Please enter the verification code'},
                      liveValidation: {...this.state.liveValidation, verificationCode : true}
      });


    }
  };
  checkIfPhoneIsValid = () => {
    if(this.state.phoneNumber) {
      if(isValidPhoneNumber(this.state.phoneNumber)){
        this.setState({ error: { ...this.state.error, phoneNumber: ''} });
        return 1;
      }
      this.setState({ error: { ...this.state.error, phoneNumber: 'The phone number is not valid'},
        liveValidation: {...this.state.liveValidation, phoneNumber : true}
      });
      return 0;
    }
    this.setState({ error: { ...this.state.error, phoneNumber: 'Please enter a phone number'},
                    liveValidation: {...this.state.liveValidation, phoneNumber : true}
    });
    return 0;
  };
  changePhoneNumber = (phone) =>{
    this.setState({
      phoneNumber: phone
    },() => {
      if (this.state.liveValidation.phoneNumber) {
        this.checkIfPhoneIsValid();
      }
    });
  };
  validateField = (e) => {
    if(e.target.value) {
      let validationTypeString = e.target.getAttribute('validationtype');
      let validationArray = validationTypeString.split('|');
      validationArray.forEach((validationType) =>{
        if(!isValid(e.target.value,validationType)) {
          let errorMessage = '';
          if(validationType === 'required'){
            errorMessage = e.target.getAttribute('fieldname') + ' is required';
          }
          this.setState({ error: { ...this.state.error, [e.target.name]: errorMessage} });
        } else {
          this.setState({ error: { ...this.state.error, [e.target.name]: ''} });
        }
      });
    }
  };
  sendAgain = () => {
    window.recaptchaVerifier.clear();
    this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
    this.sendCode();
  };
  changeNumber = () => {
    window.recaptchaVerifier.clear();
    this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`;
    this.setState({
      step: 0
    });
  };
  emailVerification = () => {
    let _this = this;
    let actionCodeSettings = {
      url: 'http://' + window.location.hostname + '/login',
      // url: 'https://localhost:3000'
    };
    fire.auth().currentUser.sendEmailVerification(actionCodeSettings).then(function() {
      _this.setState({
        verificationEmailSent: true
      })
    }).catch(function(error) {
      _this.setState({
        emailVerificationError: error.message
      });
    });
  }
  render(){
    const {step, verificationEmailSent} = this.state;
    return (
      <VerifyContainer>
        <LeftSideImage image={step ? Images.background3 : Images.background2}>
          <FullLogo />
        </LeftSideImage>
        <RightSide>
          {verificationEmailSent ?
            <EmailVerificationSentContainer>
              <BigText>
                A verification email was sent to your email address. Follow the instructions to complete registration.
              </BigText>
            </EmailVerificationSentContainer>
            :
            <div>
              <Title>
                {step ?
                  'Enter Verification Code'
                  :
                  'Enter Phone Number'
                }
              </Title>
              <Subtitle>Step 2 of 3</Subtitle>
              <ActionText style={{paddingTop: "15px"}}>
                {step ?
                  "We've sent a verification code to " + this.state.phoneNumber
                  :
                  'We will send you a one-time verification code '
                }
              </ActionText>
              <RegisterFormContainer>
                <ErrorsContainer>
                  <ErrorText errorText={this.state.error.phoneNumber}/>
                  <ErrorText errorText={this.state.error.verificationCode}/>
                  <ErrorText errorText={this.state.emailVerificationError}/>
                </ErrorsContainer>
                {step ?
                  <div>
                    <PrimaryInput value={this.state.verificationCode} name="verificationCode"
                                  onChange={(e) => this.handleChange(e, this.state.liveValidation.verificationCode)}
                                  label="Verification Code"
                                  type="text" haserror={(this.state.error.verificationCode !== '').toString()}
                                  validationtype="required" onBlur={(e) => this.validateField(e)}
                                  fieldname="Verification code"
                    />
                    <div>
                      <ActionPrimaryText style={{margin: '10px 0'}} onClick={this.sendAgain}>
                        Send again&nbsp;
                        <FontAwesomeIcon icon={faGreaterThan} style={{fontSize: '9px'}}/>
                      </ActionPrimaryText>
                      <ActionPrimaryText style={{margin: '10px 0'}} onClick={this.changeNumber}>
                        Change number&nbsp;
                        <FontAwesomeIcon icon={faGreaterThan} style={{fontSize: '9px'}}/>
                      </ActionPrimaryText>
                      <ActionPrimaryText style={{margin: '10px 0'}} onClick={this.emailVerification}>
                        Use email verification&nbsp;
                        <FontAwesomeIcon icon={faGreaterThan} style={{fontSize: '9px'}}/>
                      </ActionPrimaryText>
                    </div>
                  </div>
                  :
                  <PhoneNumberInput value={this.state.phoneNumber}
                                    haserror={this.state.error.phoneNumber}
                                    onChange={phone => this.changePhoneNumber(phone)}
                  />
                }
                {step ?
                  <div>
                    {this.state.sendingToServer ?
                      <ButtonLoader loading={this.state.sendingToServer} style={{marginTop: "20px"}}/>
                      :
                      <PrimaryButton onClick={this.verifyCode} style={{marginTop: "20px"}}>Verify</PrimaryButton>
                    }
                  </div>
                  :
                  <div>
                    {this.state.sendingToServer ?
                      <ButtonLoader loading={this.state.sendingToServer} style={{marginTop: "20px"}}/>
                      :
                      <PrimaryButton onClick={this.sendCode} style={{marginTop: "20px"}}>Send Code</PrimaryButton>
                    }
                  </div>
                }
                <CenteredContainer style={{paddingTop: "45px"}}>
                  <GrayLogo/>
                </CenteredContainer>
                <div ref={ref => this.recaptchaWrapperRef = ref}>
                  <div id="recaptcha-container"></div>
                </div>
              </RegisterFormContainer>
            </div>
          }
        </RightSide>
      </VerifyContainer>
    );
  }
}
VerifyPhone.propTypes = {
  history: PropTypes.object
};
export default withRouter(VerifyPhone);
