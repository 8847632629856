import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PropagateLoader } from 'react-spinners';
const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
`;
class LoadingScreen extends React.Component {
  render(){
    return (
      <Container>
        <PropagateLoader
          sizeUnit={"px"}
          size={15}
          loading={this.props.loading}
          color="#66BB6A"
        />
      </Container>
    );
  }
}
LoadingScreen.propTypes = {
  loading: PropTypes.bool,
};
export default LoadingScreen;
