// MyStoreCheckout.js
import React from 'react';
import {Elements} from 'react-stripe-elements';
import PropTypes from "prop-types";

import InjectedCheckoutForm from '../components/CheckoutForm';

class CreditCardInput extends React.Component {
  constructor(props){
    super(props);
  }
  setSendingToServer = (status) => {
    this.child.setSendingToServer(status);
  };
  render() {
    return (
      <Elements  fonts={[{cssSrc: 'https://fonts.googleapis.com/css?family=Noto+Sans'}]}>
        <InjectedCheckoutForm stripeTokenCallback={this.props.stripeTokenCallback} onRef={ref => (this.child = ref)}/>
      </Elements>
    );
  }
}
CreditCardInput.propTypes = {
  stripeTokenCallback: PropTypes.func
};
export default CreditCardInput;
