import {NetWorthActionTypes} from '../constants/NetWorthActionTypes';

export const netWorthActions = {
  fetchNetWorthStart,
  fetchNetWorthSuccess,
  fetchNetWorthFailure,
  addAsset,
  updateAsset,
  deleteAsset
};

function fetchNetWorthStart() {
  return {type: NetWorthActionTypes.FETCH_NET_WORTH_START};
}

function fetchNetWorthSuccess(data) {
  return {type: NetWorthActionTypes.FETCH_NET_WORTH_SUCCESS, data};
}

function fetchNetWorthFailure(error) {
  return {type: NetWorthActionTypes.FETCH_NET_WORTH_FAILURE, error};
}

function addAsset(asset) {
  return {type: NetWorthActionTypes.ADD_ASSET, asset};
}

function updateAsset(asset) {
  return {type: NetWorthActionTypes.UPDATE_ASSET, asset};
}

function deleteAsset(id) {
  return {type: NetWorthActionTypes.DELETE_ASSET, id};
}
