import {AccountDetails} from '../constants/AccountDetails';

export const accountActions = {
  setProfile,
  setAccount,
  setTransactions,
  setCurrentClient,
  setProfilePhotoUrl
};
function setProfilePhotoUrl(url) {
  return {type : AccountDetails.SET_PROFILE_PHOTO_URL, url};
}
function setProfile(profile){
  return {type : AccountDetails.SET_PROFILE, profile};
}

function setAccount(account){
  return {type : AccountDetails.SET_SELECTED_ACCOUNT, account};
}
function setTransactions(data) {
  return {type : AccountDetails.SET_SELECTED_TRANSACTIONS, data};
}
function setCurrentClient (data) {
  return {type : AccountDetails.SET_CURRENT_CLIENT, data};
}
