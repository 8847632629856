import React from 'react';
import PropTypes from 'prop-types';
import { PopupBox, PopupTitle, PopupSquareImage, PopupDescription } from '../../css/styled_components/PopupComponents.js';
import PrimaryButton from '../PrimaryButton';

class AssetSavingAccount extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <PopupBox>
        <PopupSquareImage src={require("../../images/icons/checking.svg")}/>
        <PopupTitle>Checking and saving accounts</PopupTitle>
        <PopupDescription>You can integrate your financial institutions from accounts page.</PopupDescription>
        <PrimaryButton onClick={this.props.onOpenAccounts}>Open accounts</PrimaryButton>
        <PrimaryButton onClick={this.props.onCancel}
                       style={{marginTop: "10px"}}
                       outlinedStyle>
          Cancel
        </PrimaryButton>
      </PopupBox>
    );
  }
}
AssetSavingAccount.propTypes = {
  onOpenAccounts: PropTypes.func,
  onCancel: PropTypes.func
}
export default AssetSavingAccount;
