/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {StripeProvider} from 'react-stripe-elements';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import {Switch,Route,withRouter} from 'react-router-dom';
import theme from '../theme/theme';
import fire from '../config/fire';
import TermOfService from '../containers/TermOfService';
import VerifyPhone from "../containers/VerifyPhone";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {accountActions} from "../actions/accountActions";
import AuthRoute from './AuthRoute';
import LoadingScreen from "../components/LoadingScreen";
import Dashboard from '../containers/Dashboard';
import Login from '../containers/Login';
import ForgotPassword from '../containers/ForgotPassword';
import SelectPlan from '../containers/SelectPlan';
import CreditCardInput from '../containers/CreditCardInput';
import PaymentButton  from '../containers/PaymentButton';
import AddAccounts from '../containers/AddAccounts'
import Accounts from '../containers/Accounts';
import TopNavbar from '../containers/TopNavbar';
import AccountExpanded from '../containers/AccountExpanded';
import Transactions from '../containers/Transactions';
import Budget from '../containers/Budget';
import NetWorth from '../containers/NetWorth';
import NetWorthAssets from '../containers/NetWorthAssets';
import NetWorthAssetsDetails from '../containers/NetWorthAssetsDetails';
import NetWorthAddAsset from '../containers/NetWorthAddAsset';
import moment from "moment/moment";
import apiConfig from "../config/api";
import axios from "axios/index";
import AdvisorTopBar from '../containers/AdvisorTopBar';
import AdvisorDashboard from '../containers/AdvisorDashboard';
import {getProfilePhotoUrl} from '../data/Storage';

// import fire from '../config/fire';
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
  html, body, #app, #app>div {
    height: 100%
  }
  &:hover {
     a{
        cursor:pointer
     }
  }
`;
class App extends React.Component {
  constructor(props){
    super(props);
    this.ref = fire.firestore().collection('users')
    this.state= {
      loading: true,
      advisorError: ''
    }
    this.institutions = fire.firestore().collection('institutions')
    this.payments = fire.firestore().collection('payments')
  }
  componentDidMount(){
    // firebase.auth().signOut()
    fire.auth().onAuthStateChanged(this.onAuthStateChanged)
  }
  onAuthStateChanged = (user) => {
    let _this = this;
    if (!user) {
      this.setState({
        loading: false
      })
    } else {
      this.ref.doc(user.uid).get().then(function(doc) {
        if (doc.exists) {
          //user was created
          getProfilePhotoUrl().then((url) => {
            return url;
          }, () => {
            return null;
          }).then((profilePhotoUrl) => {
            const data = doc.data();
            let profileInfo = {
              firstName: data.firstName,
              lastName: data.lastName,
              email:data.email,
              phoneNumber: data.phoneNumber,
              description: data.description,
              advisorId: data.id
            };
            if(data.advisor) {
              _this.props.actions.setProfile(profileInfo);
              _this.props.actions.setProfilePhotoUrl(profilePhotoUrl);
              if(_this.props.location.state && _this.props.location.state.from){
                _this.props.history.push(_this.props.location.state.from.pathname);
                _this.setState({
                  loading: false
                });
                return
              }
              //redirect him to the previous route or dashboard
              let nextRoute = _this.props.location.pathname;
              if(nextRoute === '/'){
                nextRoute = '/advisor/dashboard'
              }
              _this.props.history.push(nextRoute);
              _this.setState({
                advisorError: '',
                loading: false
              });
            } else {
              fire.auth().signOut()
              _this.setState({
                advisorError: 'You can login only with an advisor account',
                loading: false
              });
            }
          })
        } else {
          fire.auth().signOut()
        }
      }).catch(function() {
        // console.log("Error getting document:", error);
      });
    }
  };
  getUpdatedTransactions = (data, callBack) => {
    let _this = this;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: apiConfig.baseUrl + '/transactions/update',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          accounts: data
        }
      }).then((response) => {
        let data = response.data.data
        let dataLength = data.length;
        let newTransactionsObjectForCategory = [];
        data.forEach((institution, masterIndex) => {
          if(!institution.error){
            _this.setState({
              updating: 1
            })
            let oldInstitutionData =  Object.assign({}, _this.state.accountsById[institution.doc_id]);
            institution.data.accounts.forEach((account) => {
              //check if account exists, if it does not we add it
              if(!oldInstitutionData.accounts[account.account_id]) {
                oldInstitutionData.accounts[account.account_id] = account
                //add new transactions for misc
                newTransactionsObjectForCategory = newTransactionsObjectForCategory.concat(account.transactions)
              }
              //check if new transaction exists
              let newTransactions = false;
              if(account.transactions.length) {
                let lastAccountData = Object.assign({}, oldInstitutionData.accounts[account.account_id])
                let oldTransactions = Object.assign([], lastAccountData.transactions)
                account.transactions.forEach((newTransaction) => {
                  let transactionExists = false;
                  lastAccountData.transactions.forEach((transaction) => {
                    if(newTransaction.transaction_id === transaction.transaction_id){
                      transactionExists = true;
                    }
                  })
                  if(!transactionExists){
                    newTransactions = true;
                    oldTransactions.unshift(newTransaction)
                    //add new transactions for misc
                    newTransactionsObjectForCategory.push(newTransaction)
                  }
                })
                if(newTransactions){
                  oldInstitutionData.accounts[account.account_id].transactions = oldTransactions;
                }
                oldInstitutionData.accounts[account.account_id].balances = account.balances;
              }
            });
            //format transactions and write to database
            let obj = oldInstitutionData.accounts
            let result = Object.keys(obj).map(function(key) {
              return obj[key];
            });
            // oldInstitutionData.accounts = result;
            _this.institutions.doc(institution.doc_id).set({
              'accounts': result,
              'lastUpdated': moment().format('YYYY-MM-DD, HH:mm')
            }, {merge: true}).then(() => {
              if(++masterIndex === dataLength && callBack) {
                _this.callBack()
              }
            }).catch(() => {
              // console.log('writing error', error)
            })
          }
        })
        if(newTransactionsObjectForCategory.length) {
          _this.addNewTransactionsToPayments(newTransactionsObjectForCategory)
        }
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
      })
    })
  };
  addNewTransactionsToPayments = (newTransactions) => {
    let _this = this;
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid );
    query.get().then(function(querySnapshot) {
      if(!querySnapshot.empty){
        querySnapshot.forEach(function(doc) {
          if (doc.data().misc) {
            if(doc.data().newTransactions) {
              let oldData = doc.data().newTransactions;
              let newData = oldData.concat(newTransactions)
              _this.payments.doc(doc.id).set({
                'newTransactions': newData
              }, {merge: true}).then(() => {
                // console.log(' update new transaction')
              }).catch(() => {
                // console.log('writing error1', error)
              })
            } else {
              _this.payments.doc(doc.id).set({
                'newTransactions': newTransactions
              }, {merge: true}).then(() => {
                // console.log(' added mis new transaction')
              }).catch(() => {
                // console.log('writing error2', error)
              })
            }
          }
        })
      }
    });
  };
  clearError = () => {
    this.setState({
      advisorError: ''
    })
  };
  render() {
    // const activeStyle = { color: 'blue' };
    return (
        <ThemeProvider theme={theme}>
          <StripeProvider apiKey="pk_test_MhfFhPk9qhdqUuKrL9GeSKxw">
          <div style={{height: "100%"}}>
            <GlobalStyle/>
            {this.state.loading ?
              <LoadingScreen loading={this.state.loading}/>
              :
              <div style={{height: '100%'}}>
                <AdvisorTopBar path={this.props.location.pathname}/>
                <TopNavbar path={this.props.location.pathname}/>
                <Switch>
                  <Route exact path="/" render={(props) => <Login {...props} advisorError={this.state.advisorError} clearError={this.clearError} />} />
                  {/*<Route path="/login" component={Login} />*/}
                  <AuthRoute path="/verify-phone" component = {VerifyPhone} />
                  <AuthRoute path="/advisor/dashboard" component={AdvisorDashboard} />
                  <AuthRoute path="/dashboard" component={Dashboard} />
                  <AuthRoute path='/select-plan' component={SelectPlan} />
                  <Route path="/terms" component = {TermOfService} />
                  <Route path='/forgot-password' component={ForgotPassword} />
                  <Route path='/store-checkout' component={CreditCardInput} />
                  <Route path='/button-checkout' component={PaymentButton} />
                  <AuthRoute path='/add-accounts' component={AddAccounts} />
                  <AuthRoute path='/accounts' component={Accounts}/>
                  <AuthRoute path='/account-expanded' component={AccountExpanded}/>
                  <AuthRoute path='/transactions' component={Transactions} />
                  <AuthRoute path='/budget' component={Budget}/>
                  <AuthRoute path='/net-worth' component={NetWorth}/>
                  <AuthRoute path='/assets' component={NetWorthAssets}/>
                  <AuthRoute path='/assets-details/:category' component={NetWorthAssetsDetails}/>
                  <AuthRoute path='/add-assets' component={NetWorthAddAsset}/>
                </Switch>
              </div>
            }
          </div>
          </StripeProvider>
        </ThemeProvider>
    );
  }
}
function mapStateToProps({account}){
  return { profile : account};
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
App.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps,null,{pure:false})(withRouter(App));
