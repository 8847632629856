import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import fire from '../config/fire';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';

import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
import FormValidator from '../components/FormValidator'
import placeholderImage from '../images/avatar.png'
import PrimaryInput from '../components/PrimaryInput';
import ErrorText from '../components/ErrorText';
import Modal from '../components/Modal';
import AddClient from '../components/modal/AddClient';
import {RightCardArrow} from '../components/shared_components/Icons';
import UploadProfilePhotoModal from '../components/modal/UploadProfilePhotoModal';
import {getClientProfilePhotoUrl} from '../data/Storage';




const DashboardFlexContainer = styled.div`
  display: block;
  margin-top: 80px;

  @media (min-width: 839px) {
      display: flex;
    }
`;

const DashboardContainer = styled.div`
  padding: 0;
  width: 100%;

      @media (min-width: 839px) {
      width: 1025px;
    }
`;

const FlexCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const MyProfile = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  width: 30%;
  min-height: 438px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  @media (max-width: 839px) {
      width: 60%;
      margin: 0 auto;
  }
`;


const ClientsCard = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  width: 65%;
  height: 438px;
  overflow: hidden;
  padding: 20px;
  @media (max-width: 839px) {
      width: 100%;
      margin-top: 20px;
  }
`;

const StyledH2 = styled.h2`
  font-family: 'NotoSans-Regular',serif ;
  color: #454B54;
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
  text-align: center;
`;
const ProfileImage = styled.img`
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin-top: 20px;
    &:hover{
      cursor: pointer;
    }
`;
const CustomButton = styled.button`
    border: 1px solid #66BB6A;
    border-radius: 2px;
    padding: 12px 20px;
    color: #66BB6A;
    margin: 22px 5px 0;
    width: 164px;
    background: transparent;
    &:focus{
      background-color: transparent;
    }
`;
const NameContainer = styled.div`
  font-family: 'NotoSans-Bold',serif ;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;
const DetailsContainer = styled.div`
  font-family: 'NotoSans-Regular',serif ;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 10px;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 20px;
`;
const AddItemSection = styled.div`
    button {
    background-color: transparent;
    border: none;
    padding: 0 5px;
    &:focus{
      background-color: transparent;
    }
  
    p {
      margin: 0;
      color: #ECECEC;
      font-size: 3rem;
      font-weight: 200;
      display: flex;
      align-items: center;
    }
  }
`;
const ClientsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const ClientItem = styled.div`
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  &:hover{
    cursor: pointer;
  }
`;
const ClientImg = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
`;
const ClientName = styled.div`
  font-family: 'NotoSans-Bold',serif ;
  font-size: 0.9rem;
`;
const StatusText = styled.div`
  font-family: 'NotoSans-Regular',serif ;
  font-size: 0.9rem;
`;
const StyledSvg = styled.svg`
    height: 22px;
    width: auto;
    &:hover{
      cursor: ${props=>props.status === 'pending' ? 'not-allowed' : 'pointer'};
    }
`;
class AdvisorDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: 'firstName',
        method: 'isEmpty',
        validWhen: false,
        message: 'First name is required.'
      },
      {
        field: 'lastName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Last name is required.'
      }
    ])
    this.submitted = false
    this.state = {
      firstName: '',
      lastName: '',
      description: '',
      phoneNumber: '',
      validation: this.validator.valid(),
      editing: false,
      changed:false,
      clients: [],
      advisorId: '',
      showPhotoModal: false,
      clientsProfilePicture: {

      }
    };
    this.users = fire.firestore().collection('users')
  }
  componentDidMount() {
    this.setState({
      firstName: this.props.profile.firstName,
      lastName: this.props.profile.lastName,
      description: this.props.profile.description,
      phoneNumber: this.props.profile.phoneNumber,
      email: this.props.profile.email,
      advisorId: this.props.profile.advisorId,
      addClientModal: false,
    })
    this.getClients(null);
  }
  getClients = (callback) => {
    let _this = this;
    this.users.doc(fire.auth().currentUser.uid).get().then(function (doc) {
      _this.getClientsPhotoUrl(doc.data().clients)
      _this.setState({
        clients: doc.data().clients || []
      }, () => {
        if(callback) {
          callback()
        }
      })
    });
  };
  formatAmountToUsd = (amount) => {
    return new Intl.NumberFormat().format(Math.round(amount));
  }
  handleChange = (e) =>  {
    this.setState({
      [e.target.name]: e.target.value,
      changed: true
    });
  };
  goToEditMode = () => {
    this.setState({
      editing: true
    })
  };
  saveData = () => {
    let _this = this;
    const validation = this.validator.validate(this.state)
    this.setState({ validation })
    this.submitted = true
    if (validation.isValid) {
      if(_this.state.changed) {
        _this.getClients(_this.saveOnServer)
      } else {
        _this.setState({
          editing: false
        })
      }
    }
  }
  saveOnServer = () => {
    let clients = this.state.clients;
    clients.forEach((client) => {
      this.users.doc(client.id).set({
        'advisors': [{
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
          description: this.state.description,
          id: this.state.advisorId
        }]
      }, {merge: true}).then(() => {
        // console.log('success ')
      }).catch(() => {
        // console.log('writing error')
      })
    })
    let _this = this;
    this.users.doc(fire.auth().currentUser.uid).set({
      'firstName': this.state.firstName,
      'lastName': this.state.lastName,
      'description': this.state.description,
      'phoneNumber': this.state.phoneNumber
    }, {merge: true}).then(() => {
      _this.setState({
        editing: false,
        changed: false
      })
      _this.props.actions.setProfile({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email:this.state.email,
        phoneNumber: this.state.phoneNumber,
        description: this.state.description
      })
    }).catch(() => {
      // console.log('writing error')
    })
  }
  openModal = () => {
    this.setState({
      addClientModal: true
    })
  };
  handleCloseModal = () => {
    this.setState({
      addClientModal: false
    })
  };
  setClientList = (clients) => {
    this.setState({
      clients: clients
    })
  };
  viewClientData = (clientId) => {
    this.props.actions.setCurrentClient({
      id: clientId
    });
    this.props.history.push('/accounts');
  };
  handleClosePhotoModal = () => {
    this.setState({
      showPhotoModal: false
    });
  };
  handleOpenPhotoModal = () => {
    this.setState({
      showPhotoModal: true
    });
  };
  getClientsPhotoUrl = (clients) => {
    let _this = this;
    clients.forEach((client) => {
      getClientProfilePhotoUrl(client.id).then((url) => {
        let clientsPhotos = JSON.parse(JSON.stringify(_this.state.clientsProfilePicture));
        clientsPhotos[client.id] = url;
        _this.setState({
          clientsProfilePicture: clientsPhotos
        });
      }, () => {
        let clientsPhotos = JSON.parse(JSON.stringify(_this.state.clientsProfilePicture));
        clientsPhotos[client.id] = placeholderImage;
        _this.setState({
          clientsProfilePicture: clientsPhotos
        });
      })
    })


  };
  render(){
    let {profilePhotoUrl} = this.props.profile
    let hasProfilePhoto = !!profilePhotoUrl;
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    const addClientModal = this.state.addClientModal
      ? (<Modal onClose={this.handleCloseModal}>
        <AddClient
          closeModal={this.handleCloseModal}
          setClientList={this.setClientList}
          clients={this.state.clients}
          advisorFirstName={this.state.firstName}
          advisorLastName={this.state.lastName}
          advisorId={this.state.advisorId}
        />
      </Modal>) : null;
    const changePhotoModal = this.state.showPhotoModal
      ? (<Modal onClose={this.handleClosePhotoModal}>
        <UploadProfilePhotoModal isEditing={hasProfilePhoto} onClose={this.handleClosePhotoModal}/>
      </Modal>) : null;
    return (
      <DashboardFlexContainer>
        <FlexSpacer/>
        <DashboardContainer>
          <FlexCardContainer>
            <MyProfile>
                <StyledH2>
                  My profile
                </StyledH2>
                <ProfileImage src={profilePhotoUrl === null ? placeholderImage : profilePhotoUrl} onClick={this.handleOpenPhotoModal}/>
                {this.state.editing ?
                  <div style={{marginTop: '10px'}}>
                    <ErrorsContainer>
                      <ErrorText errorText={validation.firstName.message} />
                      <ErrorText errorText={validation.lastName.message} />
                    </ErrorsContainer>
                    <PrimaryInput value={this.state.firstName} name="firstName"
                                  onChange={(e) => this.handleChange(e)}
                                  label="First Name" type="text"
                                  fieldname="firstName"
                                  haserror={validation.firstName.isInvalid.toString()}
                    />
                    <PrimaryInput value={this.state.lastName} name="lastName"
                                  onChange={(e) => this.handleChange(e)}
                                  label="Last Name" type="text"
                                  fieldname="lastName"
                                  haserror={validation.lastName.isInvalid.toString()}
                    />
                    <PrimaryInput value={this.state.description} name="description"
                                  onChange={(e) => this.handleChange(e)}
                                  label="Description" type="text"
                                  fieldname="description"
                                  haserror='false'
                    />
                    <PrimaryInput value={this.state.phoneNumber} name="phoneNumber"
                                  onChange={(e) => this.handleChange(e)}
                                  label="Phone Number" type="text"
                                  fieldname="phoneNumber"
                                  haserror='false'
                    />
                  </div>
                  :
                  <div>
                    <NameContainer>
                      {this.state.firstName} {this.state.lastName}
                    </NameContainer>
                    <DetailsContainer>
                      {this.state.description === '' ? '(description)' : this.state.description}
                    </DetailsContainer>
                    <DetailsContainer>
                      {this.state.email}
                    </DetailsContainer>
                    <DetailsContainer>
                      {this.state.phoneNumber === '' ? '(phone number)' : this.state.phoneNumber}
                    </DetailsContainer>
                  </div>
                }
                <CustomButton onClick={this.state.editing ? this.saveData : this.goToEditMode}>
                  {this.state.editing ? 'Save' : 'Edit'}
                </CustomButton>
            </MyProfile>

            <ClientsCard>
              <StyledH2 style={{textAlign: 'left'}}>
                Clients
              </StyledH2>
              <ClientsList>
                {this.state.clients.map((client, i) => {
                    return <ClientItem key={i} onClick={() => client.status === 'pending' ? null : this.viewClientData(client.id)}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <ClientImg src={this.state.clientsProfilePicture[client.id] || placeholderImage}/>
                        <ClientName style={{marginLeft:'10px'}}>{client.firstName} {client.lastName}</ClientName>
                        <StatusText>{client.status === 'pending' ? '(pending)' : ''}</StatusText>
                      </div>
                      <StyledSvg viewBox="0 0 129 129" status={client.status}>
                        {RightCardArrow}
                      </StyledSvg>
                    </ClientItem>
                })}
              </ClientsList>
              <AddItemSection>
                <button onClick={this.openModal}>
                  <p>+ <span style={{fontSize: '1rem', marginLeft: '10px'}}>Add Client</span></p>
                </button>
              </AddItemSection>
            </ClientsCard>
          </FlexCardContainer>

          <BottomLogoSection/>
        </DashboardContainer>
        <FlexSpacer/>
        {addClientModal}
        {changePhotoModal}
      </DashboardFlexContainer>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
AdvisorDashboard.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object,
  actions: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AdvisorDashboard));

