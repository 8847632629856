import styled from 'styled-components';

export const PopupBox = styled.div`
  width: 412px;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
export const PopupBoxShortTop = styled(PopupBox)`
  padding-top: 30px;
`;
export const PopupErrorContainer = styled.div`
  margin-bottom: 20px;
`;
export const PopupTitle = styled.h1`
  margin: 0px;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.secondaryColor};
`;
export const PopupDescription = styled.p`
  margin: 12px 0 30px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.lightText};
`;
export const PopupSquareImage = styled.img`
  width: 74px;
  height: 74px;
  margin: 0 auto 5px;
`;
