import styled from 'styled-components';

export const TopTitlesContainer = styled.div`
    display: flex;
    margin-top: 64px;

  .top-spacer-container {
        margin: 34px 0 10px;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0 10%;

        @media (min-width: 839px) {
            padding: 0;
            width: 1025px;
        }
    .plus-icon-button {
        font-size: 3rem;
        font-weight: 200;
        margin: 0;
        border: none;
        background-color: transparent;
        color: #969697;
    }
  }
`;

export const RowContainer = styled.div`
    display: flex;

  .inner-spacer-container {
    display: block;
    width: 100%;
    padding: 0 10%;

    @media (min-width: 839px) {
      padding: 0;
      width: 1025px;
    }
  }
`;

export const BackArrowButton = styled.button`
    background-color: transparent;
    border: none;
    display: flex;

    &:focus {
        background-color: transparent;
    }
`;

export const BackArrowSvg = styled.svg`
    height: 33px;
    width: 28px;
`;

export const AssetsTitle = styled.h1`
    font-family: "NotoSans-Regular", serif;
    font-size: 1.7rem;
    color: #454B54;
    margin: 0 0 0 12px;
`;
