// import nodemailer from 'nodemailer';
import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import fire from '../config/fire';
import moment from 'moment'
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';

import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
import {RefreshIcon, DownArrowIcon} from '../components/shared_components/Icons';
import { VictoryPie } from 'victory';


const DashboardFlexContainer = styled.div`
  display: block;
  margin-top: -180px;
  @media (min-width: 839px) {
      display: flex;
    }
`;

const DashboardContainer = styled.div`
  padding: 0;
  width: 100%;
      @media (min-width: 839px) {
      width: 1025px;
    }
`;

const HeightSpacer = styled.div`
    height: 70px;
`;

const FlexCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const BankingCard = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  @media (min-width: 839px) {
    margin: 0 24px 0 0;
    width: 500px;
    height: 438px;
    }
`;

const BankingTopCardAmountContainer = styled.div`
  background-color: #FFFFFF;	
  padding: 24px 0 16px 0;
  .banking-top-card-title {
    color: #454B54;	
    font-size: 1rem;	
    margin: 0;
    font-weight: bold;
    text-align: center;
  }
  .banking-top-card-amount {
    color: #454B54;	
    font-family: "Noto Serif";	
    font-size: 2rem;	
    margin: 6px 0;
    text-align: center;
    font-weight: bold;	
    letter-spacing: 0.5px;
  }
  .banking-top-card-updated-container {
    text-align: center;
    display: flex;
    align-items: center;
  }
  .banking-top-card-updated-text {
    color: rgba(69,75,84,0.4);
    text-align: center;
    margin:0 12px 0 0;
    display: inline;
    font-weight: 400;
    font-size: .8rem;
  }
`;

const BankingBottomCardConatiner = styled.div`
  background-color: #F9F9FA;
  .banking-bottom-card-pie-container {
    display: flex;
  }
  .banking-bottom-card-pie-chart {
    width: 50%;
    margin-top: -35px;
  }
  .banking-bottom-card-amount {
    text-align: center;
    color: #454B54;	
    font-family: "Noto Serif";	
    font-size: 1.2rem;	
    margin: 0;
    font-weight: bold;
  }
  .banking-bottom-card-account {
    text-align: center;
    color: rgba(69,75,84,0.4);	
    margin: 0;
    padding: 3px 0 19px 0;
    font-size: .8rem;
  }
  .banking-bottom-card-amount-spacer {
    height: 100px;
  }
`;

const BudgetCard = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (min-width: 839px) {
    width: 500px;
    height: 438px;
    }
`;

const BudgetCardConatiner = styled.div`
  background: linear-gradient(180deg, #1B1E22 0%, #454B54 100%);	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.2);
  display: block;
  .budget-card-projected-balance-container {
    display: flex;
    align-items: center;
    padding: 28px 0 0;
  }
  .budget-card-title-projected-balance-dropdown {
    border: 1px solid green;
  }
  .budget-card-title-projected-balance {
    color: #FFFFFF;	
    margin: 0;
    font-size: .8rem;	
    text-align: center;
  }
  .budget-card-title-amount {
    color: #66BB6A;	
    margin: 18px 0 0;
    font-family: "Noto Serif Display";
    font-size: 2.3rem;	
    font-weight: 600;
    text-align: center;
  }
  .budget-card-pie-container {
    display: flex;
  }
  .budget-card-pie-chart {
    margin-top: -40px;
    width: 50%;
  }
`;

const BudgetBottomAmounts = styled.div`
  display: flex;
`;

const BudgetCardBottomAmountContainer = styled.div`
.budget-card-bottom-title {
  color: ${props => props.color};
  font-size: .8rem;
  margin: 0;
  text-align: center;
}
.budget-card-bottom-amount {
  color: ${props => props.color};
  margin: 8px 0 30px 0;
  font-family: "Noto Serif";
  font-size: 1.4rem;	
  font-weight: bold;
  text-align: center;
}`;

const OvalDateDropdownContainer = styled.div`
  display: inline-block;
  position: relative; 
`;

const ProjectedBalanceDropdownList = styled.ul`
  display: none;
  width: 135px;
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 10px;
  background-color: #2ab7a9;
  border-radius: 0 0 4px 4px;
  
  .dropdown-list-item {
    width: 100%;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    button {
      width: 100%;
      border: none;
      background-color: transparent; 
    }
    &:hover {
      background-color: rgba(255,255,255,0.4);
      color: white;
    }
  }
  ${({ showProjectedDropdown }) => showProjectedDropdown && `
  display: block;
  `}
`;

const OvalDateContainer = styled.button`
    display: inline-block;
    border: none;
    padding: 8px 16px;
    font-size: .8rem;
    border-radius: 19px;	
    margin: 0 0 0 10px;
    color: white;
    background-color: rgba(255,255,255,0.12);
    width: 135px;
    ${({ showProjectedDropdown }) => showProjectedDropdown && `
    border-radius: 19px 19px 0 0;
    `}
`;

const DownArrowContainer = styled.svg`
    height: 7px;
    width: 30px;
    margin: 0;
`;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionActive: '',
      subscriptionType: '',
      bankingAccountAmount: 0,
      bankingAccountTitle: '',
      showProjectedDropdown: false,
      profileAdvisorInputError: false,
      showProfileAdvisorEmailSent: false,
      profileAdvisorEmail: '',
      userData: {},
      advisorData: null,
      accounts: [],
      recurring: [],
      lastUpdated: null,
      dropDownData: [
        {
          label: 'Today',
          end: true,
          increment: 'day',
          value: 1
        },
        {
          label: 'In One Week',
          end: false,
          increment: 'week',
          value: 1,
        },
        {
          label: 'In Two Weeks',
          end: false,
          increment: 'week',
          value: 2,
        },
        {
          label: 'End of Month',
          end: true,
          increment: 'month',
          value: 1
        }
      ],
      dropDownSelected: {
        label: 'In One Week',
        end: false,
        increment: 'week',
        value: 1
      },
      advisorProfilePicture: null

    };
    this.institutions = fire.firestore().collection('institutions')
  }

  componentDidMount() {
    let _this = this;

    const unsubscribeUsers = fire.firestore().collection('users').doc(this.props.profile.currentClientId).onSnapshot(function(doc){
      _this.setState({
        userData: doc.data(),
        subscriptionActive: doc.data().activeSubscription,
        subscriptionType:doc.data().activePlan
      })
    });


    let query = fire.firestore().collection('payments').where("user_id", "==", this.props.profile.currentClientId);
    const unsubscribePayments = query.onSnapshot(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const data = doc.data();

        _this.setState({
          recurring: data.recurring
        })
      });
    });

    const unsubscribeInst = this.institutions.where("user_id", "==", this.props.profile.currentClientId).onSnapshot(function (querySnapshot) {
      let lastUpdated = ''
      let institutionsData = [];
      querySnapshot.forEach(function (doc) {
        institutionsData.push(doc.data());
        lastUpdated = doc.data().lastUpdated;
      });


      const getAllAccounts = (institutions) => {
        if (institutions.length >= 1) {

          const newArrays = institutions.map((inst) => {
            let bankName = inst.name;
            return inst.accounts.map((account) => {
              return {...account, bank_name: bankName}
            });
          });
          return [].concat.apply([], newArrays);

        } else {
          return institutions;
        }
      }
      const updatedInstitutionsData = getAllAccounts(institutionsData);

      _this.setState({
        accounts: updatedInstitutionsData,
        lastUpdated: lastUpdated
      })
    });

    const unsubscribeList = [unsubscribeInst, unsubscribePayments, unsubscribeUsers ]

    _this.setState({
      unsubscribe: unsubscribeList
    })

  }

  componentWillUnmount = () => {
    const {unsubscribe} = this.state;
    if (unsubscribe.length === 0) {
      return;
    }

    unsubscribe.forEach(func => {
      func();
    })
  };

  logout = () => {
    fire.auth().signOut().then(() => {
      this.props.history.push('/');
    })
  };

  formatAmountToUsd = (amount) => {
    return new Intl.NumberFormat().format(Math.floor(amount));
  }

  profileCardInputHandleChange = (event) => {
    this.setState({profileAdvisorEmail: event.target.value});
  }

  changeProjectedDate = (item) => {
    this.setState({
      dropDownSelected: item
    })
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
  }

  sendAdvisorEmail = () => {
    const {profileAdvisorEmail} = this.state;

    // async function main(){
    //   // Generate test SMTP service account from ethereal.email
    //   // Only needed if you don't have a real mail account for testing
    //   let testAccount = await nodemailer.createTestAccount();

    //   // create reusable transporter object using the default SMTP transport
    //   let transporter = nodemailer.createTransport({
    //     host: "smtp.ethereal.email",
    //     port: 587,
    //     secure: false, // true for 465, false for other ports
    //     auth: {
    //       user: testAccount.user, // generated ethereal user
    //       pass: testAccount.pass // generated ethereal password
    //     }
    //   });

    //   // send mail with defined transport object
    //   await transporter.sendMail({
    //     from: '"Alto" <info@alto.com>', // sender address
    //     to: profileAdvisorEmail, // list of receivers
    //     subject: "Hello from Alto!", // Subject line
    //     text: "Sign up today with Alto!", // plain text body
    //     html: "<b>Sign up today with Alto!</b>" // html body
    //   });
    // }

    if (this.validateEmail(profileAdvisorEmail)) {
      // main();
      this.setState({profileAdvisorInputError : false, showProfileAdvisorEmailSent: true});
    }
    else {
      this.setState({profileAdvisorInputError : true});
    }
  }

  projectedBalanceDropdownHandler = () => {
    this.setState(prevState => {
      return ({
        showProjectedDropdown: !prevState.showProjectedDropdown
      })
    });
  };

  bankingReduceTotal = (accounts) => {
    return accounts.reduce((accum, account) => {
      if(account.type !== 'loan' && account.type !== 'credit') {
        return accum += account.balances.current;
      }
      else if(account.type === 'credit') {
        return accum -= account.balances.current;
      } else {
        return accum;
      }
    }, 0);
  }

  get getBankingTotal() {
    const { accounts } = this.state;
    if (!accounts) {
      return 0;
    }

    return this.bankingReduceTotal(accounts);
  }

  get findAccountsData() {
    const { accounts } = this.state;

    if (!accounts) {
      return null;
    }


    const sortAccounts = (a, b) => {
      return b.balances.current - a.balances.current;
    }

    const accountsSorted = accounts.sort(sortAccounts).filter((account) => {
      return account.type !== "loan" && account.subtype !== "credit card";
    });

    const fourAccounts = accountsSorted.slice(0, 4);
    const totalAmmount = this.bankingReduceTotal(fourAccounts);

    const makeData = fourAccounts.map(account => {

      const bankInfoText = `${account.bank_name} (${account.name} ...${account.mask})`

      return ({x: bankInfoText,
        y: ((account.balances.current + 10 * 140) / totalAmmount),
        label: account.balances.current
      })
    });

    return makeData;
  }


  get transactionTimeLimit() {
    let timeLimit = '';
    if(this.state.dropDownSelected.end) {
      timeLimit = moment().endOf(this.state.dropDownSelected.increment)
    } else {
      timeLimit = moment().add(this.state.dropDownSelected.value, this.state.dropDownSelected.increment).endOf('day');
    }
    return timeLimit
  }

  budgetTransactionReducerFunc = (type) => {
    const { accounts, recurring } = this.state;
    if (!accounts || !recurring || accounts.length === 0) {
      return null;
    }
    const timeLimit = this.transactionTimeLimit;

    const filterLogic = (amount) => {
      if (type === 'spendingReducer') {
        if (amount < 0) {
          return true;
        } else {
          return false;
        }
      }
      else if (type === 'incomeReducer') {
        if (amount > 0) {
          return true;
        } else {
          return false;
        }
      }
      else {
        return false
      }
    }

    const totalTranscations = Math.abs(accounts.reduce((accum, account) => {
      if (account.transactions.length === 0) {
        return accum;
      } else {
        return account.transactions.reduce((accum, transaction) => {
          if(moment(transaction.date).isBefore(timeLimit) && filterLogic(transaction.amount)){
            return accum + transaction.amount;
          } else {
            return accum;
          }
        },0);
      }
    }, 0));

    const recurringBillSum = recurring.reduce((accum, bill) => {
      if(moment(bill.next_installment).isBefore(timeLimit) && bill.scheduled && !filterLogic(bill.amount)){
        return accum + bill.amount;
      } else {
        return accum;
      }
    }, 0)


    if (type === 'spendingReducer') {
      return totalTranscations + recurringBillSum;
    } else if (type === 'incomeReducer') {
      return totalTranscations - recurringBillSum;
    }

  }

  get recurringSumTotal() {
    const { recurring } = this.state;
    if (!recurring) {
      return null;
    }

    const timeLimit = this.transactionTimeLimit;

    return recurring.reduce((accum, bill) => {
      if(moment(bill.next_installment).isBefore(timeLimit) && bill.scheduled && moment(bill.next_installment).isAfter(moment().subtract(1,'days'))){
        return accum + bill.amount;
      } else {
        return accum;
      }
    }, 0)
  }

  get getbudgetTotalAmount() {
    return this.getBankingTotal - this.recurringSumTotal;
  }

  get budgetSpendingValue() {
    const { accounts } = this.state;
    if (!accounts || accounts.length === 0) {
      return 0;
    }

    return this.budgetTransactionReducerFunc('spendingReducer');
  }

  get budgetIncomeValue() {
    const { accounts } = this.state;
    if (!accounts || accounts.length === 0) {
      return 0;
    }

    return this.budgetTransactionReducerFunc('incomeReducer');
  }

  get getBudgetChartData() {
    const total = Math.abs(this.budgetSpendingValue) + this.budgetIncomeValue;
    const spending = Math.abs(this.budgetSpendingValue) * 100 / total;
    const income = this.budgetIncomeValue * 100 / total;

    return [{x: "spending", y: spending}, {x: "income", y: income}];
  }

  render(){
    const { accounts } = this.state;

    return (
      <DashboardFlexContainer>
        <FlexSpacer/>
        <DashboardContainer>

          <HeightSpacer/>

          <FlexCardContainer>
            <BankingCard>
              <BankingTopCardAmountContainer>
                <h2 className="banking-top-card-title">
                  Banking
                </h2>
                <h2 className="banking-top-card-amount">
                  ${accounts.length === 0 ? 0 : this.formatAmountToUsd(this.getBankingTotal)}
                </h2>
                <div className="banking-top-card-updated-container">
                  <FlexSpacer/>
                  {this.state.lastUpdated ?
                    <React.Fragment>
                      <p className="banking-top-card-updated-text">
                        Updated {moment(this.state.lastUpdated).fromNow()}
                      </p>
                      {RefreshIcon}
                    </React.Fragment>
                    : null}
                  <FlexSpacer/>
                </div>
              </BankingTopCardAmountContainer>

              <BankingBottomCardConatiner>


                <div className="banking-bottom-card-pie-container">
                  <FlexSpacer/>
                  <div className="banking-bottom-card-pie-chart">

                    <svg style={{ height: 0, width: 0 }}>
                      <defs>
                        <linearGradient id="greenBackgroundGradientXL" gradientTransform="rotate(180)">
                          <stop offset="0%" stopColor="#9FDFA3"/>
                          <stop offset="100%" stopColor="#66BB6A"/>
                        </linearGradient>
                        <filter id="dropshadow" height="130%">
                          <feGaussianBlur in="SourceAlpha" stdDeviation="5"/>
                          <feOffset dx="2" dy="2" result="offsetblur"/>
                          <feComponentTransfer>
                            <feFuncA type="linear" slope="2"/>
                          </feComponentTransfer>
                          <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                          </feMerge>
                        </filter>
                      </defs>
                    </svg>
                    <VictoryPie
                      style={{
                        labels: {
                          display: 'none'
                        },
                        data: {
                          filter: 'none',
                          // fill: ({ y }) =>
                          //   y > 30 ? 'url(#greenBackgroundGradientXL)'
                          //   : y > 20 ? '#6DC871'
                          //   : y > 10 ? '#58A15C'
                          //   : '#437B46'
                        }
                      }}
                      events={[{
                        target: "data",
                        eventHandlers: {
                          onMouseOver: () => {

                            return {
                              target: "data",
                              mutation: (props) => {
                                this.setState({bankingAccountAmount: props.slice.data.label, bankingAccountTitle: props.slice.data.x});

                                return ({ style: {...props.style, filter: "url(#dropshadow)" } } )
                              }
                            };
                          },
                          onMouseOut: () => {
                            this.setState({bankingAccountAmount: null, bankingAccountTitle: null});

                            return {
                              target: "data",
                              mutation: (props) => ({ style: {...props.style, filter: "none" } } )
                            };
                          }
                        }
                      }]}
                      data={this.findAccountsData}
                      colorScale='green'
                    />
                  </div>
                  <FlexSpacer/>
                </div>

                {this.state.bankingAccountTitle ?
                  <React.Fragment>
                    <p className="banking-bottom-card-amount">
                      ${accounts.length === 0 ? 0 : this.formatAmountToUsd(this.state.bankingAccountAmount)}
                    </p>
                    <p className="banking-bottom-card-account">
                      {this.state.bankingAccountTitle}
                    </p>
                  </React.Fragment>
                  :
                  <div className="banking-bottom-card-amount-spacer"/>
                }

              </BankingBottomCardConatiner>

            </BankingCard>

            <BudgetCard>
              <BudgetCardConatiner>
                <div className="budget-card-projected-balance-container">
                  <FlexSpacer/>
                  <p className="budget-card-title-projected-balance">
                    Projected Balance
                  </p>
                  <OvalDateDropdownContainer onClick={this.projectedBalanceDropdownHandler}>
                    <OvalDateContainer showProjectedDropdown={this.state.showProjectedDropdown}>
                      {this.state.dropDownSelected.label}
                      <DownArrowContainer viewBox="0 50 100 60">{DownArrowIcon}</DownArrowContainer>
                    </OvalDateContainer>
                    <ProjectedBalanceDropdownList showProjectedDropdown={this.state.showProjectedDropdown}>
                      {this.state.dropDownData.map((item,i) => {
                        if(item.label !== this.state.dropDownSelected.label)
                          return <li className="dropdown-list-item" key={i} onClick={() => this.changeProjectedDate(item)}><button>{item.label}</button></li>
                      })}
                    </ProjectedBalanceDropdownList>
                  </OvalDateDropdownContainer>
                  <FlexSpacer/>
                </div>

                <h2 className="budget-card-title-amount">
                  ${accounts.length === 0 ? 0 : this.formatAmountToUsd(this.getbudgetTotalAmount)}
                </h2>

                <div className="budget-card-pie-container">
                  <FlexSpacer/>
                  <div className="budget-card-pie-chart">
                    <svg style={{ height: 0, width: 0 }}>
                      <defs>
                        <linearGradient id="blueBackgroundGradient" gradientTransform="rotate(135)">
                          <stop offset="0%" stopColor="#8739E5"/>
                          <stop offset="100%" stopColor="#5496FF"/>
                        </linearGradient>
                        <linearGradient id="redBackgroundGradient" gradientTransform="rotate(135)">
                          <stop offset="0%" stopColor="#F5317F"/>
                          <stop offset="100%" stopColor="#FF7C6E"/>
                        </linearGradient>
                      </defs>
                    </svg>
                    <VictoryPie
                      style={{
                        labels: {
                          display: 'none'
                        },
                        data: {
                          filter: 'none',
                          fill: ({ x }) =>
                            x === "income" ? 'url(#blueBackgroundGradient)'
                              : 'url(#redBackgroundGradient)'
                        }
                      }}
                      events={[{
                        target: "data",
                      }]}
                      data={this.getBudgetChartData}
                    />
                  </div>
                  <FlexSpacer/>
                </div>
                <BudgetBottomAmounts>
                  <FlexSpacer/>
                  <BudgetCardBottomAmountContainer color={'#FF7C6E'}>
                    <p className="budget-card-bottom-title">
                      Spent
                    </p>
                    <p className="budget-card-bottom-amount">
                      ${accounts.length === 0 ? 0 : this.formatAmountToUsd(Math.abs(this.budgetSpendingValue))}
                    </p>
                  </BudgetCardBottomAmountContainer>
                  <FlexSpacer/>
                  <BudgetCardBottomAmountContainer color={'#5496FF'}>
                    <p className="budget-card-bottom-title">
                      Income
                    </p>
                    <p className="budget-card-bottom-amount">
                      ${this.formatAmountToUsd(this.budgetIncomeValue)}
                    </p>
                  </BudgetCardBottomAmountContainer>
                  <FlexSpacer/>
                </BudgetBottomAmounts>
              </BudgetCardConatiner>
            </BudgetCard>
          </FlexCardContainer>
          {/* User Info
        <div>
          First Name: {this.props.profile.firstName}
        </div>
        <div>
          Last Name: {this.props.profile.lastName}
        </div>
        <div>
          Email: {this.props.profile.email}
        </div>
        <div>
          Subscription active: {this.state.subscriptionActive ? 'true' : 'false'}
        </div>
        <div>
          Subscription type: {this.state.subscriptionType}
        </div>
        <button onClick={this.logout}>Log Out</button> */}
          <BottomLogoSection/>

        </DashboardContainer>
        <FlexSpacer/>
      </DashboardFlexContainer>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
Dashboard.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Dashboard));
