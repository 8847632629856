import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.button`
  width: 100%;
  height: 43px;
  text-align: center;
  letter-spacing: 0.5px;
  font-family: "NotoSans-Regular", serif;
  font-weight: 500;
  font-size: 13px;
  border-radius: 2px;
  color: ${props => props.errorStyle ? props.theme.errorColor : props.theme.primaryColor};
  background-color: white;
  border: none;

  &:hover{
    cursor: pointer;
    border: 1px #454B54 solid;
  }
  &:focus{
    background-color: white;
  }
  &:active{
    border: none;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
class TextButton extends React.Component {
  render(){
    return (
      <Container id={this.props.id}
                 onClick={this.props.onClick}
                 style={this.props.style}
                 errorStyle={this.props.errorStyle}>
        {this.props.children}
      </Container>
    );
  }
}
TextButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
  errorStyle: PropTypes.bool,
  id: PropTypes.string,
};

export default TextButton;
