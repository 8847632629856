// CardSection.js
import React from 'react';
import {CardNumberElement,CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
import styled from 'styled-components';
import '../css/paymentfont.min.css';
import ErrorText from '../components/ErrorText';
import PropTypes from "prop-types";
const ElementBorder = styled.div`
    width: 100%;
    border: ${props=>props.haserror === '' ? '1px solid rgba(69,75,84,0.2) ' : '1px solid #D7463C'} !important;	
    border-radius: 2px;  
    color: black !important;
    font-family: "NotoSans-Regular", serif !important;
    padding:.75rem !important;
    font-size: 1rem !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
    position: relative;
`;
const HalfElementBorder = styled(ElementBorder)`
  width: 50%;
  margin-top: 1rem;
`;
const Icon = styled.div`
    height: 100%;
    position: absolute;
    top: 0;
    right: 0.75rem;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 22px;
    color:#3e4db8
`;
const TwoElementsContainer = styled.div`
  display: flex;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 20px;
`;
class CardSection extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      icon: 'pf-credit-card'
    };
    this.cardBrandToPfClass = {
      'visa': 'pf-visa',
      'mastercard': 'pf-mastercard',
      'amex': 'pf-american-express',
      'discover': 'pf-discover',
      'diners': 'pf-diners',
      'jcb': 'pf-jcb',
      'unionpay' : 'pf-unionpay',
      'unknown': 'pf-credit-card',
    };
  }
  handleCardNumberChange = (object) => {
    if(!object.error){
      this.props.setErrors('','number');
    } else{
      this.props.setErrors(object.error.message,'number');
    }
    this.setState({
      icon: this.cardBrandToPfClass[object.brand]
    });
  };
  handelExpiryChange = (object) => {
    if(!object.error){
      this.props.setErrors('','expiry');
    } else{
      this.props.setErrors(object.error.message,'expiry');
    }
  };
  handleCvcChange = (object) => {
    if(!object.error){
      this.props.setErrors('','cvc');
    } else{
      this.props.setErrors(object.error.message,'cvc');
    }
  };
  render() {
    return (
      <div>
        <ErrorsContainer>
          <ErrorText errorText={this.props.errors.number} />
          <ErrorText errorText={this.props.errors.expiry} />
          <ErrorText errorText={this.props.errors.cvc} />
        </ErrorsContainer>
        <ElementBorder haserror={this.props.errors.number}>
          <CardNumberElement
            style={{base:{
              fontSize: '1rem',
              fontFamily: "Noto Sans"
            }}}
            placeholder="Card number"
            onChange={this.handleCardNumberChange}
          />
          <Icon>
            <i className={"pf "+this.state.icon} />
          </Icon>
        </ElementBorder>
        <TwoElementsContainer>
          <HalfElementBorder style={{marginRight: '2px'}} haserror={this.props.errors.expiry}>
            <CardExpiryElement
              style={{base:{
                  fontSize: '1rem',
                  fontFamily: "Noto Sans"
                }}}
              onChange={this.handelExpiryChange}
            />
          </HalfElementBorder>
          <HalfElementBorder style={{marginLeft: '2px'}} haserror={this.props.errors.cvc}>
            <CardCVCElement
              style={{base:{
                  fontSize: '1rem',
                  fontFamily: "Noto Sans"
                }}}
              onChange={this.handleCvcChange}
            />
          </HalfElementBorder>
        </TwoElementsContainer>
      </div>
    );
  }
}
CardSection.propTypes = {
  errors: PropTypes.object,
  setErrors: PropTypes.func
};
export default CardSection;
