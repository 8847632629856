import {AccountDetails} from "../constants/AccountDetails";


export function account(state = {}, action){
  switch (action.type){
    case AccountDetails.SET_PROFILE:
      return {
        ...state,
        firstName: action.profile.firstName,
        lastName:action.profile.lastName,
        email:action.profile.email,
        description: action.profile.description || '',
        phoneNumber: action.profile.phoneNumber || '',
        advisorId: action.profile.advisorId,
        profilePhotoUrl: action.profile.profilePhotoUrl
      };
    case AccountDetails.SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: accountReducer(state, action),
      };
    case AccountDetails.SET_SELECTED_TRANSACTIONS:
      return {
        ...state,
        currentTransactions: action.data.account,
        currentLogo: action.data.logo
      };
    case AccountDetails.SET_CURRENT_CLIENT:
      return {
        ...state,
        currentClientId: action.data.id
      };
    case AccountDetails.SET_PROFILE_PHOTO_URL:
      return {
        ...state,
        profilePhotoUrl: action.url,
      };
    default :
      return state;
  }
}

function accountReducer(state, action){
  switch (action.type){
    case AccountDetails.SET_SELECTED_ACCOUNT:
      return {
        ...action.account
      };
    default:
      return {
        ...state
      };
  }
}
