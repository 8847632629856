import firebase from 'firebase';
const config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: "AIzaSyB-LVp3x1FORTCM4rP6I7UC6ZBu8dbXUlY",
  authDomain: "sherpa-98bf5.firebaseapp.com",
  databaseURL: "https://sherpa-98bf5.firebaseio.com",
  projectId: "sherpa-98bf5",
  storageBucket: "sherpa-98bf5.appspot.com",
  messagingSenderId: "771747561068"
};
const fire = firebase.initializeApp(config);
const firestore = fire.firestore();
const settings = {/* your settings... */ timestampsInSnapshots: true};
firestore.settings(settings);
export default fire;
