import React from 'react';
import PropTypes from 'prop-types';
import { PopupBoxShortTop, PopupTitle, PopupErrorContainer } from '../../css/styled_components/PopupComponents.js';
import PrimaryButton from '../PrimaryButton';
import PrimaryInput from '../PrimaryInput';
import ErrorText from '../ErrorText';
import FormValidator from '../FormValidator';
import ButtonLoader from  '../ButtonLoader';
import fire from "../../config/fire";
import axios from "axios/index";
class AddClient extends React.Component {
  constructor(props){
    super(props)
    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email is required.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Please enter a valid email address.'
      },
    ])
    this.users = fire.firestore().collection('users')
    this.submitted = false
    this.state={
      email: '',
      validation: this.validator.valid(),
      sendingToServer: false
    };
  }
  handleInputChange = (e) =>  {
    this.setState({
      [e.target.name]: e.target.value,
      serverError: ''
    });
  };
  addClient = () => {
    let _this = this;
    const validation = this.validator.validate(this.state)
    this.setState({ validation })
    this.submitted = true
    if (validation.isValid) {
      _this.setState({
        sendingToServer: true
      })
      _this.searchUserOnServer()
    }
  };
  searchUserOnServer = () => {
    let _this = this
    let query = this.users.where("email", "==", this.state.email);
    query.get().then(function (querySnapshot) {
      if(!querySnapshot.docs.length) {
        _this.setState({
          serverError: 'There is no user associated with this email',
          sendingToServer: false
        });
        return;
      }
      querySnapshot.forEach(function (doc) {
        let clientAdded = false;
        let clientHasAdvisor = false;
        _this.props.clients.forEach((client) => {
          if(client.email === doc.data().email){
            clientAdded = true;
          }
        });
        if(doc.data().advisors && doc.data().advisors.length >= 1) {
          clientHasAdvisor = true
        }
        if(clientAdded){
          _this.setState({
            serverError: 'Client already added',
            sendingToServer: false
          });
        } else {
          if(clientHasAdvisor) {
            _this.setState({
              serverError: 'This client already has an advisor',
              sendingToServer: false
            });
          } else {
            _this.sendConfirmationEmail(doc.data())
          }
        }
      });
    });
  };
  addClientToAdvisor = (data) => {
    let _this = this;
    let clients = JSON.parse(JSON.stringify(this.props.clients));
    clients.push({
      firstName: data.firstName,
      lastName: data.lastName,
      id: data.id,
      status: 'pending',
      email: data.email
    });
    this.users.doc(fire.auth().currentUser.uid).set({
      'clients': clients,
    }, {merge: true}).then(() => {
      _this.props.setClientList(clients)
      _this.props.closeModal()
    }).catch(() => {
      // console.log('writing error')
    })
  };
  sendConfirmationEmail = (clientData) => {
    let _this = this;
    let advisorData = {
      firstName: this.props.advisorFirstName,
      lastName: this.props.advisorLastName,
      id: this.props.advisorId
    };
    let url = 'https://sherpa-98bf5.firebaseapp.com/accept/advisor/request/' + advisorData.id + '/' + clientData.id;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/send-advisor-client-pairing-mail',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          clientData: clientData,
          advisorData: advisorData,
          url: url
        }
      }).then((response) => {
        if(response.data.status === 'success') {
          _this.addClientToAdvisor(clientData);
        } else {
          _this.setState({
            serverError: 'Something went wrong with the mail service, please try again later',
            sendingToServer: false
          });
        }
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
        _this.setState({
          serverError: 'Something went wrong with the server, please try again later',
          sendingToServer: false
        });
      })
    })
  };
  render(){
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    return(
      <PopupBoxShortTop>
        <PopupTitle>Add Client</PopupTitle>
        <PopupErrorContainer>
          <ErrorText errorText={validation.email.message} />
          <ErrorText errorText={this.state.serverError} />
        </PopupErrorContainer>
        <PrimaryInput value={this.state.email}
                            name="email"
                            onChange={(e) =>this.handleInputChange(e)}
                            label="Email"
                            fieldname="email"
                            haserror={validation.email.isInvalid.toString()}

        />
        {this.state.sendingToServer ?
          <ButtonLoader loading={this.state.sendingToServer} style={{marginTop: '20px'}} />
          :
          <PrimaryButton onClick={this.addClient} style={{marginTop: '20px'}}>
            Send
          </PrimaryButton>
        }
      </PopupBoxShortTop>
    )
  }
}
AddClient.propTypes = {
  closeModal: PropTypes.func,
  setClientList: PropTypes.func,
  clients: PropTypes.array,
  advisorFirstName: PropTypes.string,
  advisorLastName: PropTypes.string,
  advisorId: PropTypes.string
};
export default AddClient;
