import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
const ErrorText = styled.div`
  padding-left: 10px;
`;
const Container = styled.div`
  color: #D7463C;
  font-family: 'NotoSans-Regular',serif ;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 1px 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
class PrimaryInput extends React.Component {
  render(){
    return (
      <div>
        {this.props.errorText &&
          <Container>
            <FontAwesomeIcon icon={faExclamationTriangle} style={{fontSize:"12px"}}/><ErrorText> {this.props.errorText}</ErrorText>
          </Container>
        }
      </div>
    );
  }
}
PrimaryInput.propTypes = {
  errorText: PropTypes.string,
};

export default PrimaryInput;
