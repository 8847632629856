import fire from '../config/fire';

export { getNetWorthWithSavingAccounts, addAsset, updateAsset, deleteAsset }

const addAsset = (name, amount, category, extra) => {
  return new Promise((success, error) => {
    let netWorthCollection = fire.firestore().collection('netWorth');
    let query = netWorthCollection.where("user_id", "==", fire.auth().currentUser.uid);
    let docRef;
    let newAsset = {
      name: name,
      amount: Number(amount),
      category: category,
      extra: extra
    };
    query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists) {
          docRef = doc.ref;
        }
      })
      if (docRef) {
        return docRef;
      } else {
        return netWorthCollection.add({
          user_id: fire.auth().currentUser.uid
        });
      }
    }).then((docRef) => {
      return docRef.collection('assets').add(newAsset);
    }).then((newRef) => {
      newAsset.id = newRef.id;
      success(newAsset);
    }).catch((err) => {
      error(err);
    });
  });
}

const updateAsset = (oldObject, name, amount) => {
  return new Promise((success, error) => {
    let newAsset = {
      id: oldObject.id,
      name: name,
      amount: Number(amount),
      category: oldObject.category,
      extra: oldObject.extra
    }
    let netWorthCollection = fire.firestore().collection('netWorth');
    let query = netWorthCollection.where("user_id", "==", fire.auth().currentUser.uid);
    let docRef;
    query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists) {
          docRef = doc.ref;
        }
      })
      return docRef;
    }).then((docRef) => {
      return docRef.collection('assets').doc(oldObject.id).update({
        name: newAsset.name,
        amount: newAsset.amount,
      });
    }).then(() => {
      success(newAsset);
    }).catch((err) => {
      error(err);
    });
  });
}

const deleteAsset = (id) => {
  return new Promise((success, error) => {
    let netWorthCollection = fire.firestore().collection('netWorth');
    let query = netWorthCollection.where("user_id", "==", fire.auth().currentUser.uid);
    let docRef;
    query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists) {
          docRef = doc.ref;
        }
      });
      return docRef;
    }).then((docRef) => {
      return docRef.collection('assets').doc(id).delete();
    }).then(() => {
      success(id);
    }).catch((err) => {
      error(err);
    });
  });
}

const getNetWorthWithSavingAccounts = () => {
  return new Promise((success, error) => {
    getNetWorth().then((data) => {
      return getCheckingAccountsTotal()
      .then((checkingAccountsTotal) => {
        data.assetsInAccountsTotal = checkingAccountsTotal;
        data.totalAssets = getTotalAssets(data.assets) + checkingAccountsTotal;
        data.totalLiabilities = getTotalLiabilities(data.liabilities);
        success(data);
      })
    }).catch((err) => {
      error(err);
    });
  })
}

const getTotalAssets = (assets) => {
  let total = 0;
  let amount;
  for (let i = 0; i < assets.length; i++) {
    amount = parseInt(assets[i].amount)
    if (amount) {
      total += amount
    }
  }
  return total;
};

const getTotalLiabilities = (liabilities) => {
  let total = 0;
  let amount;
  for (let i = 0; i < liabilities.length; i++) {
    amount = parseInt(liabilities[i].amount)
    if (amount) {
      total += amount
    }
  }
  return total;
};

const getCheckingAccountsTotal = () => {
  let institutionsCollection = fire.firestore().collection('institutions');
  let query = institutionsCollection.where("user_id", "==", fire.auth().currentUser.uid);
  return new Promise((success, error) => {
    query.get().then((snapshot) => {
      if (snapshot.length === 0) {
        return null;
      }
      let totalSum = 0;
      snapshot.forEach(function(doc) {
        doc.data().accounts.forEach((value) => {
          totalSum += value.balances.current;
        })
      });
      success(totalSum);
    }, (err) => {
      error(err);
    });
  });
}

const getNetWorth = () => {
  return new Promise((success, error) => {
    let netWorthCollection = fire.firestore().collection('netWorth')
    let query = netWorthCollection.where("user_id", "==", fire.auth().currentUser.uid);
    let docRef = null;
    let assets = [];
    let liabilities = [];
    query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists) {
          docRef = doc.ref;
        }
      })
      if (docRef) {
        return docRef.collection('assets').get();
      } else {
        success({ assets: [], liabilities: []});
      }
    }).then((assetsSnap) => {
      assetsSnap.forEach(function(assetDoc) {
        let a = assetDoc.data();
        a.id = assetDoc.id;
        assets.push(a);
      });
      return docRef.collection('liabilities').get();
    }).then((liabilitiesSnap) => {
      liabilitiesSnap.forEach(function(liabilityDoc) {
        let l = liabilityDoc.data();
        l.id = liabilityDoc.id;
        liabilities.push(l);
      });
      success({ assets, liabilities });
    }).catch((err) => {
      error(err);
    });
  })
}
