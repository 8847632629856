import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {formatAccountTotalFull, formatAccountTotal, formatDecimalAmount} from '../utils/accountsFormatting';

import {LeftBackArrowIcon, MenuIconButtonThree, RightCardArrow} from '../components/shared_components/Icons';
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import {bindActionCreators} from "redux";
import {accountActions} from "../actions/accountActions";


const AccountFlexContainer = styled.div`
    display: block;

    @media (min-width: 839px) {
    display: flex;
    }
`;

const ExpandedContainer = styled.div`
    width: 100%;
    padding: 0;    

    @media (min-width: 839px) {
      width: 1025px;
    }
`;

const TopSpacer = styled.div`
    height: 164px;
`;

const BotSpacer = styled.div`
    height: 60px;

    @media (min-width: 839px) {
        height: 120px;
    }
`;

const TopButtonsGrid = styled.div`
    display: flex;
`;

const BackToAccounts = styled.button`
    display: inline-flex;
    background-color: transparent;
    border: none;
    padding-left: 0px;

    &:focus {
        background-color: transparent;
    }
    .a-svg-back-arrow-icon {
        padding-top: 3px;
        width: 50px;
        height: 33px;
    }
    .a-accounts-back-button-title {
        margin: 0;
        color: #454B54;	
        font-family: "NotoSans-Regular", serif;		
        font-size: 1.6rem;	
        line-height: 33px;
    }
`;

const MenuIconButton = styled.button`
    background-color: transparent;
    border: none;

        &:focus {
        background-color: transparent;
    }
    .a-accounts-menu-three-icon {
        height: 20px;
        width: 20px;
    }
`;

const AccountMainCard = styled.div`
    margin: 26px 0 21px;
    padding: 9px 30px 30px 30px;
    border-radius: 2px;	
    background-color: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
    width: 100%;

    .a-account-main-card-icon-container {
        display: flex;
        width: 100%;
    }
    .a-account-main-icon-container-oval {	
        height: 138px;	
        width: 138px;	
        position: relative;
        z-index: 1;
        background-color: #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
    }
    .a-account-main-icon-img {
        height: 96px;
        width: 96px;
    }
`;

const GrayAccountBackground = styled.div`
    margin-top: -69px;
    background-color: #FAFAFB;
    text-align: center;
    height: 200px;

    .a-main-card-account-title-p {
        color: #454B54;
        font-family: "NotoSans-Regular",serif;
        font-size: 1rem;
        font-weight: bold;
        margin: 0 0 24px 0;
        line-height: 14px;
    }
    .a-main-card-account-ammount-h2 {
        color: #454B54;
        font-family: "NotoSans-Regular",serif;
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0 0 6px 0;
        letter-spacing: 0.5px;
        line-height: 19px;
    }
    .a-main-card-account-ammount-h2-decimals {
        font-size: .9rem;
    }
    .a-main-card-available-ammount-p {
        color: rgba(69,75,84,0.4);
        font-family: "NotoSans-Regular",serif;
        font-size: 1rem;
        margin: 0;
        line-height: 19px;
    }

`;

const HeightSpacer = styled.div`
    height: 82px;
`;

const SubAccountCards = styled.button`
    align-items: center;
    text-align: left;
    border: none;
    border-bottom: 1px solid #F2F2F2;
    border-radius: 2px;	
    background-color: #FFFFFF;	
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
    width: 100%;
    display: flex;
    padding: 30px;

        &:focus {
        background-color: transparent;
        }
        .a-sub-account-card-icon {
            height: 48px;	
            width: 48px;
        }
        .a-sub-account-items-container {
            margin-left: 30px;
        }
        .a-sub-account-card-title-h3 {
            color: #454B54;
            font-family: "NotoSans-Regular",serif;
            font-size: .9rem;
            font-weight: bold;
            margin: 0 0 6px 0;
            line-height: 14px;
        }
        .a-sub-account-card-account-p {
            color: rgba(69,75,84,0.4);
            font-family: "NotoSans-Regular",serif;
            font-size: 1rem;
            margin: 0;
            line-height: 19px;
        }
        .a-sub-account-amount-container {
            display: inline-flex;
            align-items: center;
        }
        .a-sub-account-card-amount-h3 {
            color: #454B54;
            font-family: "NotoSans-Regular",serif;
            font-size: 1rem;
            font-weight: bold;
            padding-right: 20px;
            letter-spacing: 0.5px;
            line-height: 19px;
            margin: 0;
        }
        .a-sub-account-card-amount-arrow-icon {
            height: 22px;
            width: auto;
        }
`;

const onBackArrowClick = (props) => {
    props.history.push('/accounts');
};
const goToTransactionList = (props, account, logo) => {
  let data = {
    account: account,
    logo: logo
  };
  props.actions.setTransactions(data)
  props.history.push('/transactions')
};
const formatTotalAmountHeader = (accounts) => {
    const {selectedAccount } = accounts;

    const total = selectedAccount.accounts.reduce((acc, account) => {
        if(account.type !== 'loan' && account.type !== 'credit') {
          acc += account.balances.current
        }
        if(account.type === 'credit') {
          acc -= account.balances.current
        }
        return acc;
    }, 0);

    return (
            <h2 className="a-main-card-account-ammount-h2">
                <span>{formatAccountTotal(total)}</span>
                <span className="a-main-card-account-ammount-h2-decimals">{formatDecimalAmount(total)}</span>
            </h2>
    );
};


const AccountExpanded = (props) => {
    const {selectedAccount } = props;

    // Future error handling for what to do on user refresh... etc
    if (!selectedAccount) {
        return null
    }

    return (

        <AccountFlexContainer>
            <FlexSpacer/>


        <ExpandedContainer>

            <TopSpacer/>

            <TopButtonsGrid>
                <BackToAccounts onClick={() => onBackArrowClick(props)}>
                    <svg className={"a-svg-back-arrow-icon"} viewBox="0 0 33 33">
                    {LeftBackArrowIcon}
                    </svg>
                    <h2 className="a-accounts-back-button-title">Accounts</h2>
                </BackToAccounts>
                <FlexSpacer/>
                <MenuIconButton>
                    <svg className="a-accounts-menu-three-icon" viewBox="0 0 512 512">
                        {MenuIconButtonThree}
                    </svg>
                </MenuIconButton>
            </TopButtonsGrid>

            <AccountMainCard>
                <div className="a-account-main-card-icon-container">
                    <FlexSpacer/>
                        <div className="a-account-main-icon-container-oval">
                            <FlexSpacer/>
                                <img className="a-account-main-icon-img" src={`data:image/jpeg;base64,${selectedAccount.logo}`} alt="Main Bank Icon"/>
                            <FlexSpacer/>
                        </div>
                    <FlexSpacer/>
                </div>

                <GrayAccountBackground>
                    <HeightSpacer/>
                    <p className="a-main-card-account-title-p">{selectedAccount.name}</p>
                    {formatTotalAmountHeader(props)}
                    <p className="a-main-card-available-ammount-p">available amount</p>
                </GrayAccountBackground>
            </AccountMainCard>

            {/* Bottom Account Cards */}
            {selectedAccount.accounts.map((account, index) => {
                return (
                <SubAccountCards key={index} onClick={() => goToTransactionList(props,account, selectedAccount.logo)}>
                    <img className="a-sub-account-card-icon" src={`data:image/jpeg;base64,${selectedAccount.logo}`} alt=""/>
                        <div className="a-sub-account-items-container">
                            <h3 className="a-sub-account-card-title-h3">{selectedAccount.name}</h3>
                            <p className="a-sub-account-card-account-p">{account.name} (...{account.mask})</p>
                        </div>

                        <FlexSpacer/>

                        <div className="a-sub-account-amount-container">
                            <h3 className="a-sub-account-card-amount-h3">{account.type === 'credit' ? '-':'' }{formatAccountTotalFull(account.balances.current)}</h3>
                            <svg className="a-sub-account-card-amount-arrow-icon" viewBox="0 0 129 129">
                                {RightCardArrow}
                            </svg>
                        </div>
                </SubAccountCards>
                );
            })
        }

        <BotSpacer/>
        </ExpandedContainer>
        <FlexSpacer/>

        </AccountFlexContainer>
    );
}

AccountExpanded.propTypes = {
    selectedAccount: PropTypes.object,
  };
function mapDispatchToProps (dispatch){
  return { actions: bindActionCreators(accountActions, dispatch) };
}
function mapStateToProps({account}){
    return { selectedAccount : account.selectedAccount};
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountExpanded));
