import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

import {FlexSpacer} from '../../css/styled_components/SharedComponents';
import logo from "../../images/logo_gray.png";

const BottomLogo = styled.div`
  display: flex;
  height: 150px;
  align-items: center;
  background-color: ${props => props.isBackgroundGrey ? '#FAFAFB' : 'transparent'};

  .bottom-page-logo {
    width: 90px;
    height: 50px;
  }
`; 

const BottomLogoSection = (props) => {
   const {isBackgroundGrey} = props;

   return (
      <BottomLogo isBackgroundGrey={isBackgroundGrey}>
         <FlexSpacer/>
            <img className="bottom-page-logo " src={logo} alt="Alto Logo"/>
         <FlexSpacer/>
      </BottomLogo>
   );
};

BottomLogoSection.propTypes = {
   isBackgroundGrey: PropTypes.bool
 };


export default BottomLogoSection;