import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {BoxTitle,PriceElement,NormalText,ActionPrimaryText} from "./TextElements";
import {faGreaterThan} from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  background: white;
  &:hover{
    cursor: pointer;
  }
  justify-content: space-between;
`;
class PlanOverviewBox extends React.Component {
  render(){
    return (
      <Container style={this.props.style}>
        <div>
          <BoxTitle style={{marginBottom:'10px'}}>{this.props.title}</BoxTitle>
          <div style={{display:'flex', alignItems: 'baseline'}}>
            <PriceElement>${this.props.price}</PriceElement>
            <NormalText>/month</NormalText>
          </div>
        </div>
        <div>
          <ActionPrimaryText onClick={this.props.changePlan}>Change plan&nbsp;<FontAwesomeIcon icon={faGreaterThan} style={{fontSize:'9px'}}/></ActionPrimaryText>
        </div>
      </Container>
    );
  }
}
PlanOverviewBox.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  changePlan: PropTypes.func,
  style: PropTypes.object
};

export default PlanOverviewBox;
